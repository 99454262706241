import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { 
  Typography, CircularProgress, Box, Snackbar, Alert,
  Button, Card, CardContent, Grid, Chip, Paper, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, Accordion, AccordionSummary, AccordionDetails
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SyncIcon from '@mui/icons-material/Sync';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGlobal } from '../context/globalContex';
import CampaignAnalyticsService from '../services/campaignAnalytics';

// Estilos
const Container = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4), 
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4), 
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', 
  maxHeight: '80vh', 
  overflowY: 'auto'
}));

const Title = styled(Typography)(({ theme }) => ({ 
  color: theme.palette.text.primary, 
  marginBottom: theme.spacing(3), 
  fontWeight: 600 
}));

const StatsCard = styled(Card)(({ theme }) => ({ 
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[6]
  }
}));

const EmptyStateMessage = styled(Typography)(({ theme }) => ({ 
  color: theme.palette.text.secondary, 
  textAlign: 'center', 
  padding: theme.spacing(4) 
}));

const LoadingWrapper = styled(Box)({ 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
  minHeight: '200px' 
});

const ButtonsContainer = styled(Box)(({ theme }) => ({ 
  display: 'flex', 
  gap: theme.spacing(2), 
  flexWrap: 'wrap' 
}));

const SNACKBAR_DURATION = 6000;

const CampaignAnalytics = () => {
  // Estados principales
  const [summary, setSummary] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [pendingJobs, setPendingJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [analyzing, setAnalyzing] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  
  // Hooks de navegación y contexto
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = useGlobal();
  const { user } = state;
  const userId = location.state?.userSessionId || user?.id;

  // Responsividad
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const showNotification = useCallback((message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  }, []);

  // Función para cargar datos
  const fetchData = useCallback(async () => {
    if (!userId) return;
    try {
      setLoading(true);
      // Cargar resumen
      const summaryResponse = await CampaignAnalyticsService.getUserSummary(userId);
      setSummary(summaryResponse.summary);
      
      // Cargar campañas analizadas
      const campaignsResponse = await CampaignAnalyticsService.getUserCampaigns(userId);
      setCampaigns(campaignsResponse.campaigns || []);
      
      // Cargar trabajos pendientes
      const pendingResponse = await CampaignAnalyticsService.getPendingJobs(userId);
      setPendingJobs(pendingResponse.pendingJobs || []);
    } catch (error) {
      console.error('Error fetching data:', error);
      showNotification('Error al cargar los datos de análisis', 'error');
    } finally {
      setLoading(false);
    }
  }, [userId, showNotification]);

  // Cargar datos al montar el componente
  useEffect(() => {
    if (!userId) {
      navigate('/');
      return;
    }
    fetchData();
  }, [userId, navigate, fetchData]);

  const handleRefresh = useCallback(() => {
    fetchData();
  }, [fetchData]);

  // Función para solicitar análisis de una campaña (primera vez o re-análisis)
  const handleAnalyzeCampaign = async (jobId, isReanalysis = false) => {
    try {
      setAnalyzing(true);
      showNotification(
        'El análisis se ha solicitado. Espere aproximadamente 10 minutos para que finalice. Puede seguir usando la aplicación mientras tanto.',
        'info'
      );
      
      if (isReanalysis) {
        // Si es un re-análisis, usar el nuevo endpoint
        await CampaignAnalyticsService.regenerateCampaignAnalytics(jobId, userId);
      } else {
        // Si es el primer análisis, usar el endpoint original
        await CampaignAnalyticsService.getCampaignAnalytics(jobId, userId);
      }
      
      await fetchData(); // Recargar datos después de la solicitud
    } catch (error) {
      console.error('Error al solicitar análisis:', error);
      showNotification(`Error al ${isReanalysis ? 're-analizar' : 'analizar'} la campaña`, 'error');
    } finally {
      setAnalyzing(false);
    }
  };

  // Función para ver detalles de un análisis
  const handleViewCampaignDetails = (jobId) => {
    navigate(`/campaign-details/${jobId}`, { state: { userId } });
  };

  // Función para descargar el archivo .xlsm
  const handleDownloadCampaign = async (jobId) => {
    try {
      showNotification('Preparando descarga...', 'info');
      // Se asume que CampaignAnalyticsService.downloadCampaign es un endpoint que retorna un blob
      const response = await CampaignAnalyticsService.downloadCampaign(jobId, userId);
      // Crear URL para el blob y forzar descarga
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `campaign-${jobId}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      showNotification('Descarga iniciada');
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
      showNotification('Error al descargar el archivo', 'error');
    }
  };

  // Función para volver a la pantalla principal
  const handleBackToMain = () => {
    navigate('/pending-responses', { state: { userSessionId: userId } });
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  // Agrupar análisis por campaña (usando el nombre de campaña)
  const groupedCampaigns = useMemo(() => {
    const groups = {};
    campaigns.forEach(analysis => {
      const key = analysis.campaignName;
      if (!groups[key]) groups[key] = [];
      groups[key].push(analysis);
    });
    return groups;
  }, [campaigns]);

  return (
    <>
      <Container>
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: isSmallScreen ? 'column' : 'row', 
            justifyContent: 'space-between', 
            alignItems: isSmallScreen ? 'stretch' : 'center', 
            mb: 3, 
            gap: 2 
          }}
        >
          <Title variant={isSmallScreen ? 'h5' : 'h4'}>Análisis de Campañas</Title>
          <ButtonsContainer>
            <Button 
              variant="contained" 
              onClick={handleRefresh} 
              color="primary" 
              fullWidth={isSmallScreen} 
              startIcon={<RefreshIcon />} 
              disabled={loading}
            >
              {loading ? 'Actualizando...' : 'Actualizar Datos'}
            </Button>
            <Button 
              variant="outlined" 
              onClick={handleBackToMain} 
              color="secondary" 
              fullWidth={isSmallScreen}
              startIcon={<ArrowBackIcon />}
            >
              Volver a Conversaciones
            </Button>
          </ButtonsContainer>
        </Box>

        {loading ? (
          <LoadingWrapper>
            <CircularProgress size={40} thickness={4} />
          </LoadingWrapper>
        ) : (
          <>
            {/* Resumen de estadísticas */}
            {summary && (
              <Box mb={4}>
                <Typography variant="h6" gutterBottom>Resumen General</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={3}>
                    <StatsCard>
                      <CardContent>
                        <Typography color="textSecondary" gutterBottom>
                          Campañas Totales
                        </Typography>
                        <Typography variant="h4" component="div">
                          {summary.totalCampaigns}
                        </Typography>
                      </CardContent>
                    </StatsCard>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <StatsCard>
                      <CardContent>
                        <Typography color="textSecondary" gutterBottom>
                          Conversaciones iniciadas
                        </Typography>
                        <Typography variant="h4" component="div">
                          {summary.totalSent}
                        </Typography>
                      </CardContent>
                    </StatsCard>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <StatsCard>
                      <CardContent>
                        <Typography color="textSecondary" gutterBottom>
                          Total de personas que respondieron
                        </Typography>
                        <Typography variant="h4" component="div">
                          {summary.totalResponses}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {summary.totalSent > 0 ? `${((summary.totalResponses / summary.totalSent) * 100).toFixed(1)}%` : '0%'} de tasa de respuesta
                        </Typography>
                      </CardContent>
                    </StatsCard>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <StatsCard>
                      <CardContent>
                        <Typography color="textSecondary" gutterBottom>
                          Oportunidades Detectadas
                        </Typography>
                        <Typography variant="h4" component="div">
                          {summary.totalOpportunities}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {summary.totalSent > 0 ? `${((summary.totalOpportunities / summary.totalSent) * 100).toFixed(1)}%` : '0%'} de tasa de conversión
                        </Typography>
                      </CardContent>
                    </StatsCard>
                  </Grid>
                </Grid>
              </Box>
            )}

            {/* Trabajos pendientes de análisis */}
            {pendingJobs.length > 0 && (
              <Box mb={4}>
                <Typography variant="h6" gutterBottom>Campañas Pendientes de Análisis</Typography>
                <TableContainer component={Paper}>
                  <Table aria-label="tabla de campañas pendientes">
                    <TableHead>
                      <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Mensajes Enviados</TableCell>
                        <TableCell>Acciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pendingJobs.map(job => (
                        <TableRow key={job.id}>
                          <TableCell>{job.campaignName}</TableCell>
                          <TableCell>{formatDate(job.startDate)}</TableCell>
                          <TableCell>{job.totalSent}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => handleAnalyzeCampaign(job.id, false)} // false = no es re-análisis
                              disabled={analyzing}
                              startIcon={<AssessmentIcon />}
                            >
                              {analyzing ? 'Analizando...' : 'Analizar'}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}

            {/* Campañas analizadas agrupadas por campaña */}
            {campaigns.length > 0 ? (
              <Box>
                <Typography variant="h6" gutterBottom>Campañas Analizadas</Typography>
                {Object.keys(groupedCampaigns).map(campaignName => {
                  const jobIdForAnalysis = groupedCampaigns[campaignName][0].jobId;
                  return (
                    <Accordion key={campaignName}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Box 
                          sx={{ 
                            display: 'flex', 
                            justifyContent: 'space-between', 
                            alignItems: 'center', 
                            width: '100%' 
                          }}
                        >
                          <Typography variant="subtitle1">{campaignName}</Typography>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleAnalyzeCampaign(jobIdForAnalysis, true); // true = es re-análisis
                            }}
                            disabled={analyzing}
                            startIcon={<SyncIcon />}
                          >
                            {analyzing ? 'Analizando...' : 'Re-analizar'}
                          </Button>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Fecha</TableCell>
                              <TableCell>Acciones</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {groupedCampaigns[campaignName].map(analysis => (
                              <TableRow key={analysis.id}>
                                <TableCell>{formatDate(analysis.startDate)}</TableCell>
                                <TableCell>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={() => handleViewCampaignDetails(analysis.jobId)}
                                  >
                                    Ver detalles
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    onClick={() => handleDownloadCampaign(analysis.jobId)}
                                    sx={{ ml: 1 }}
                                  >
                                    Descargar
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Box>
            ) : summary && !loading ? (
              <EmptyStateMessage variant="body1">
                No hay campañas analizadas. Selecciona "Analizar" en una campaña pendiente para comenzar.
              </EmptyStateMessage>
            ) : null}

            {!summary && !campaigns.length && !pendingJobs.length && !loading && (
              <EmptyStateMessage variant="body1">
                No hay datos de campañas disponibles. Envía mensajes masivos primero para poder analizarlos.
              </EmptyStateMessage>
            )}
          </>
        )}
      </Container>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={SNACKBAR_DURATION}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity} 
          elevation={6} 
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CampaignAnalytics;