import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { 
 Typography, CircularProgress, Box, Snackbar, Alert,
 Button, LinearProgress,
 TextField, MenuItem, Select, FormControl, InputLabel, InputAdornment, IconButton
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import PauseIcon from '@mui/icons-material/Pause';
import CancelIcon from '@mui/icons-material/Cancel';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGlobal } from '../context/globalContex';
import { getPendingResponses, setAutonomousMode, reconnectClient } from '../services/client';
import MessageService from '../services/messageService';
import JobsService from '../services/jobService'; // Añadida esta importación
import { FixedSizeList as List } from 'react-window';
import { styled, useTheme } from '@mui/material/styles';


// Estilos (sin cambios)
const ChatElementContainer = styled(Box)(({ theme }) => ({
 display: 'flex',
 alignItems: 'flex-start',
 padding: '12px 16px',
 borderBottom: `1px solid ${theme.palette.divider}`,
 cursor: 'pointer',
 transition: 'background-color 0.2s',
 '&:hover': { backgroundColor: theme.palette.action.hover }
}));

const ChatInfo = styled(Box)({ flex: 1 });
const ChatHeader = styled(Box)({ display: 'flex', alignItems: 'center', marginBottom: 8 });
const StatusIndicator = styled(Box)(({ color }) => ({
 width: 10, height: 10, borderRadius: '50%', backgroundColor: color, marginRight: 8, flexShrink: 0
}));
const ChatTitle = styled(Typography)(({ theme }) => ({ fontWeight: 600, fontSize: 15 }));
const LastMessage = styled(Typography)(({ theme }) => ({
 fontSize: 14, color: theme.palette.text.secondary, whiteSpace: 'normal', overflow: 'visible', lineHeight: 1.5
}));
const FilterContainer = styled(Box)(({ theme }) => ({
 display: 'flex', gap: theme.spacing(2), marginBottom: theme.spacing(3),
 [theme.breakpoints.down('sm')]: { flexDirection: 'column' },
}));
const SNACKBAR_DURATION = 6000;
const Container = styled(Box)(({ theme }) => ({
 marginTop: theme.spacing(4), backgroundColor: theme.palette.background.paper,
 padding: theme.spacing(4), borderRadius: theme.shape.borderRadius,
 boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', maxHeight: '80vh', overflowY: 'auto'
}));
const Title = styled(Typography)(({ theme }) => ({ 
 color: theme.palette.text.primary, 
 marginBottom: theme.spacing(3), 
 fontWeight: 600 
}));
const EmptyStateMessage = styled(Typography)(({ theme }) => ({ 
 color: theme.palette.text.secondary, 
 textAlign: 'center', 
 padding: theme.spacing(4) 
}));
const LoadingWrapper = styled(Box)({ 
 display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' 
});
const ButtonsContainer = styled(Box)(({ theme }) => ({ 
 display: 'flex', gap: theme.spacing(2), flexWrap: 'wrap' 
}));
const ConversationsContainer = styled(Box)(({ theme }) => ({
 marginTop: theme.spacing(2), borderRadius: theme.shape.borderRadius,
 border: `1px solid ${theme.palette.divider}`, overflow: 'hidden'
}));

// Función auxiliar para convertir objetos Binary a base64
const binaryToBase64 = (binary) => {
  if (!binary) return null;
  
  try {
    // Si ya es una string base64
    if (typeof binary === 'string') return binary;
    
    // Si es un objeto Binary con buffer
    if (binary.buffer) {
      return btoa(String.fromCharCode.apply(null, new Uint8Array(binary.buffer)));
    }
    
    // Si es un Buffer
    if (binary instanceof Uint8Array || (typeof Buffer !== 'undefined' && binary instanceof Buffer)) {
      return btoa(String.fromCharCode.apply(null, binary));
    }
    
    return null;
  } catch (error) {
    console.error('Error converting binary to base64:', error);
    return null;
  }
};

// Función para procesar mensajes en bruto
const procesarMensajeBruto = (msg) => {
  // Si es null o undefined, retornamos null
  if (!msg) return null;
  
  // Si ya está procesado con mediaType, lo devolvemos tal cual
  if (msg.mediaType) return msg;
  
  // Si es un string simple, creamos un objeto básico
  if (typeof msg === 'string') {
    return {
      role: 'user',
      text: msg,
      timestamp: new Date().toISOString() // Timestamp actual en formato ISO
    };
  }
  
  // Obtenemos el timestamp y el rol del mensaje
  let timestamp = msg.timestamp;
  
  // Convertir el timestamp al formato ISO si no lo está ya
  if (timestamp) {
    if (typeof timestamp === 'number') {
      // Si es un timestamp en segundos, convertir a milisegundos y luego a ISO
      timestamp = new Date(timestamp * 1000).toISOString();
    } else if (typeof timestamp === 'string' && !timestamp.includes('T')) {
      // Si es una string pero no en formato ISO
      timestamp = new Date(timestamp).toISOString();
    }
    // Si ya es ISO, lo dejamos igual
  } else {
    // Si no hay timestamp, usamos la fecha actual
    timestamp = new Date().toISOString();
  }
  
  console.log("timestamp procesado:", timestamp);
  const role = msg.key?.fromMe ? 'assistant' : 'user';
  
  // Si es un objeto WhatsApp con imagen
  if (msg.message && msg.message.imageMessage) {
    const imageMsg = msg.message.imageMessage;
    let thumbnailBase64 = binaryToBase64(imageMsg.jpegThumbnail);
    
    return {
      role,
      text: imageMsg.caption || '',
      mediaType: 'image',
      mediaData: {
        preview: thumbnailBase64 ? `data:image/jpeg;base64,${thumbnailBase64}` : null,
        mimetype: imageMsg.mimetype,
        dimensions: { 
          width: imageMsg.width || 0, 
          height: imageMsg.height || 0 
        }
      },
      timestamp
    };
  }
  
  // Si es un objeto WhatsApp con audio
  if (msg.message && msg.message.audioMessage) {
    const audioMsg = msg.message.audioMessage;
    let waveformBase64 = binaryToBase64(audioMsg.waveform);
    
    return {
      role,
      text: '',
      mediaType: 'audio',
      mediaData: {
        waveform: waveformBase64,
        duration: audioMsg.seconds || 0,
        mimetype: audioMsg.mimetype || 'audio/ogg'
      },
      timestamp
    };
  }
  
  // Si es un objeto WhatsApp con video
  if (msg.message && msg.message.videoMessage) {
    const videoMsg = msg.message.videoMessage;
    let thumbnailBase64 = binaryToBase64(videoMsg.jpegThumbnail);
    
    return {
      role,
      text: videoMsg.caption || '',
      mediaType: 'video',
      mediaData: {
        preview: thumbnailBase64 ? `data:image/jpeg;base64,${thumbnailBase64}` : null,
        mimetype: videoMsg.mimetype,
        duration: videoMsg.seconds || 0,
        dimensions: { 
          width: videoMsg.width || 0, 
          height: videoMsg.height || 0 
        }
      },
      timestamp
    };
  }
  
  // Si es un objeto WhatsApp con documento
  if (msg.message && msg.message.documentMessage) {
    const docMsg = msg.message.documentMessage;
    let thumbnailBase64 = binaryToBase64(docMsg.jpegThumbnail);
    
    return {
      role,
      text: docMsg.fileName || 'Documento',
      mediaType: 'document',
      mediaData: {
        preview: thumbnailBase64 ? `data:image/jpeg;base64,${thumbnailBase64}` : null,
        mimetype: docMsg.mimetype,
        fileName: docMsg.fileName
      },
      timestamp
    };
  }
  
  // Si es un mensaje de texto normal (conversation o extendedTextMessage)
  if (msg.message) {
    let text = '';
    
    if (msg.message.conversation) {
      text = msg.message.conversation;
    } else if (msg.message.extendedTextMessage && msg.message.extendedTextMessage.text) {
      text = msg.message.extendedTextMessage.text;
    }
    
    if (text) {
      return {
        role,
        text,
        timestamp
      };
    }
  }
  
  // Si llegamos aquí y el mensaje tiene un campo 'text', lo usamos directamente
  if (msg.text) {
    return {
      role: msg.role || role,
      text: msg.text,
      timestamp: msg.timestamp ? timestamp : new Date().toISOString()
    };
  }
  
  // Si no pudimos procesar el mensaje de ninguna forma conocida,
  // devolvemos el mensaje original para no perder información
  return msg;
};

// Componente ChatElement
const ChatElement = React.memo(({ phoneNumber, lastMessage, createAt, userId, conversation, navigate, interes }) => {
 const getStatusColor = () => {
   const currentTime = new Date();
   let lastUpdateTime;
   
   try {
     lastUpdateTime = new Date(createAt);
     // Verificar si la fecha es válida
     if (isNaN(lastUpdateTime.getTime())) {
       console.warn('Fecha inválida:', createAt);
       lastUpdateTime = new Date(); // Usar fecha actual como fallback
     }
   } catch (e) {
     console.error('Error al convertir fecha:', e, createAt);
     lastUpdateTime = new Date(); // Usar fecha actual como fallback
   }
   
   const hoursDiff = (currentTime - lastUpdateTime) / (1000 * 60 * 60);
   if (hoursDiff < 24) return '#4CAF50';
   if (hoursDiff >= 24 && hoursDiff < 72) return '#FF9800';
   return '#F44336';
 };

 const getInteresColor = (interes) => {
   switch (interes) {
     case 'Nuevo': return '#9E9E9E';
     case 'Respuesta positiva': return '#66BB6A';
     case 'Respuesta negativa': return '#EF5350';
     case 'Oportunidad': return '#43A047';
     case 'Informado': return '#29B6F6';
     case 'Posible SI': return '#66BB6A';
     case 'Posible NO': return '#EF5350';
     case 'Ganado': return '#43A047';
     case 'No interesado': return '#E53935';
     case 'Posible Futuro Interes': return '#FFB74D';
     default: return '#9E9E9E';
   }
 };

 // Detectar si el último mensaje contiene una imagen
 const hasImage = useMemo(() => {
  if (!conversation.summarizedHistory || conversation.summarizedHistory.length === 0) return false;
  
  const lastMessageObj = conversation.summarizedHistory[conversation.summarizedHistory.length - 1];
  
  // Comprobar si usa la nueva estructura con mediaType
  if (lastMessageObj && lastMessageObj.mediaType === 'image') {
    return true;
  }
  
  // Buscar estructura en bruto de WhatsApp
  if (lastMessageObj && lastMessageObj.message && lastMessageObj.message.imageMessage) {
    return true;
  }
  
  // Fallback al método antiguo para compatibilidad
  return typeof lastMessageObj === 'object' && !!lastMessageObj.image;
}, [conversation]);

// Detectar si el último mensaje contiene audio
const hasAudio = useMemo(() => {
  if (!conversation.summarizedHistory || conversation.summarizedHistory.length === 0) return false;
  
  const lastMessageObj = conversation.summarizedHistory[conversation.summarizedHistory.length - 1];
  
  // Comprobar si usa la nueva estructura
  if (lastMessageObj && lastMessageObj.mediaType === 'audio') {
    return true;
  }
  
  // Buscar estructura en bruto de WhatsApp
  if (lastMessageObj && lastMessageObj.message && lastMessageObj.message.audioMessage) {
    return true;
  }
  
  return false;
}, [conversation]);

const handleClick = () => {
  // Asegurarnos de que la conversación tiene el historial ordenado por timestamp
  if (conversation.summarizedHistory && Array.isArray(conversation.summarizedHistory)) {
    // Crear una copia ordenada del historial por timestamp
    const sortedHistory = [...conversation.summarizedHistory].sort((a, b) => {
      // Obtener timestamps como objetos Date
      let timeA, timeB;
      
      try {
        // Intentar convertir el timestamp a un objeto Date
        timeA = a.timestamp ? new Date(a.timestamp) : new Date(0);
        timeB = b.timestamp ? new Date(b.timestamp) : new Date(0);
        
        // Verificar si son fechas válidas
        if (isNaN(timeA.getTime())) timeA = new Date(0);
        if (isNaN(timeB.getTime())) timeB = new Date(0);
      } catch (e) {
        console.error('Error al ordenar mensajes por timestamp:', e);
        return 0;
      }
      
      return timeA.getTime() - timeB.getTime();
    });
    
    // Actualizar el historial ordenado en la conversación
    conversation.summarizedHistory = sortedHistory;
  }
  
  navigate('/chat', { state: { userId, conversationId: conversation.chatId || conversation.id, conversation } });
};

return (
  <ChatElementContainer onClick={handleClick}>
    <ChatInfo>
      <ChatHeader>
        <StatusIndicator color={getStatusColor()} />
        <ChatTitle variant="subtitle1">
          Número de teléfono: {phoneNumber}
        </ChatTitle>
        <Typography variant="caption" sx={{ 
          ml: 'auto', 
          bgcolor: getInteresColor(interes), 
          color: 'white', 
          px: 1, 
          py: 0.5, 
          borderRadius: 1 
        }}>
          {interes || 'Nuevo'}
        </Typography>
      </ChatHeader>
      <LastMessage variant="body2">
        Último mensaje: 
        {hasImage && '[Imagen] '}
        {hasAudio && '[Audio] '}
        {lastMessage || 'No hay mensajes'}
      </LastMessage>
    </ChatInfo>
  </ChatElementContainer>
);
});

// Componente PendingResponses
const PendingResponses = () => {
 // Estados principales
 const [responses, setResponses] = useState([]);
 const [loading, setLoading] = useState(false);
 const [isAutonomous, setIsAutonomous] = useState(false);
 const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
 const [premiumDialogOpen, setPremiumDialogOpen] = useState(false);
 const [isReconnecting, setIsReconnecting] = useState(false);

 // Estados para filtros
 const [timeFilter, setTimeFilter] = useState('all');
 const [interesFilter, setInteresFilter] = useState('all');
 const [searchTerm, setSearchTerm] = useState('');
 const [inputSearchTerm, setInputSearchTerm] = useState('');

 // Estados de paginación para MessageService (con paginación)
 const [page, setPage] = useState(1);
 const [pagination, setPagination] = useState(null);
 const [showPagination, setShowPagination] = useState(false);
 const limit = 100;

 // Estados para el job masivo
 const [jobStatus, setJobStatus] = useState(null);
 const [checkingJob, setCheckingJob] = useState(false);

 // Referencias y controladores
 const isInitialMount = useRef(true);
 const lastFetchedFilter = useRef({ time: 'all', interes: 'all', search: '' });

 // Hooks de navegación y contexto
 const location = useLocation();
 const navigate = useNavigate();
 const { logoutUser, state } = useGlobal();
 const { user } = state;
 const userId = location.state?.userSessionId;

 // Responsividad
 const theme = useTheme();
 const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

 // Opciones de interés
 const opcionesInteres = useMemo(() => [
   'Nuevo', 'Respuesta positiva', 'Respuesta negativa', 'Oportunidad', 'Informado', 'Posible SI', 'Posible NO', 'Ganado', 'No interesado', 'Posible Futuro Interes'
 ], []);

 const showNotification = useCallback((message, severity = 'success') => {
   setSnackbar({ open: true, message, severity });
 }, []);

 // Función para procesar el historial de mensajes
 const procesarHistorialMensajes = useCallback((mensajes) => {
  if (!Array.isArray(mensajes)) return [];
  
  return mensajes.map(msg => {
    // Procesar mensaje según su formato
    const procesado = procesarMensajeBruto(msg);
    
    // Asegurarse de que el timestamp sea una fecha ISO válida
    if (procesado && procesado.timestamp) {
      // Si es una cadena ISO, la dejamos tal cual
      if (typeof procesado.timestamp === 'string' && procesado.timestamp.includes('T')) {
        // Ya es un formato ISO, no necesita conversión
      } 
      // Si es un número (en segundos), lo convertimos a milisegundos y luego a ISO
      else if (typeof procesado.timestamp === 'number') {
        const date = new Date(procesado.timestamp * 1000); // Multiplicar por 1000 si está en segundos
        procesado.timestamp = date.toISOString();
      }
      // Si es una string pero no en formato ISO
      else if (typeof procesado.timestamp === 'string' && !procesado.timestamp.includes('T')) {
        try {
          procesado.timestamp = new Date(procesado.timestamp).toISOString();
        } catch (e) {
          console.error('Error al convertir timestamp:', e);
          procesado.timestamp = new Date().toISOString();
        }
      }
    } else {
      // Si no hay timestamp, añadimos uno actual
      procesado.timestamp = new Date().toISOString();
    }
    
    return procesado;
  });
 }, []);

 // Función para obtener el último mensaje de una conversación
 const getLastMessageFromHistory = useCallback((conversation) => {
   // Usar lastMessage del backend si está disponible
   if (conversation.lastMessage && conversation.lastMessage.text) {
     return conversation.lastMessage.text;
   }
   
   // Mantener la lógica anterior como fallback
   if (!conversation?.summarizedHistory?.length) {
     return conversation?.incomingMessage?.text || 'No hay mensajes';
   }
   
   // Ordenar el historial por timestamp antes de buscar el último mensaje
   const sortedHistory = [...conversation.summarizedHistory].sort((a, b) => {
     // Convertir timestamps a objetos Date para comparación
     let dateA = new Date(0);
     let dateB = new Date(0);
     
     try {
       if (a.timestamp) dateA = new Date(a.timestamp);
       if (b.timestamp) dateB = new Date(b.timestamp);
     } catch (e) {
       console.error('Error al ordenar mensajes para último mensaje:', e);
     }
     
     return dateA.getTime() - dateB.getTime();
   });
   
   // El último mensaje es el más reciente
   const lastMsg = sortedHistory[sortedHistory.length - 1];
   
   // Buscar el último mensaje del usuario
   const userMessage = [...sortedHistory].reverse().find(msg => {
     if (typeof msg !== 'object') return false;
     return ['user', 'alumno'].includes(msg.role) || 
            ['user', 'alumno'].includes(msg.sender) ||
            ['user', 'alumno'].includes(msg.from);
   });
   
   if (!userMessage) {
     if (typeof lastMsg === 'string') return lastMsg;
     
     // Detectar si es un mensaje con media para añadir una etiqueta
     if (lastMsg.mediaType === 'image') {
       return `[Imagen] ${lastMsg.text || ''}`.trim();
     } else if (lastMsg.mediaType === 'audio') {
       return `[Audio] ${lastMsg.text || ''}`.trim();
     }
     
     // Detección de formato WhatsApp en bruto
     if (lastMsg.message) {
       if (lastMsg.message.imageMessage) {
         const caption = lastMsg.message.imageMessage.caption || '';
         return `[Imagen] ${caption}`.trim();
       }
       if (lastMsg.message.audioMessage) {
         return '[Audio]';
       }
     }
     
     return lastMsg?.text || lastMsg?.content || lastMsg?.message || 'No hay mensajes';
   }
   
   if (typeof userMessage === 'string') return userMessage;
   
   // Detectar si es un mensaje con media para añadir una etiqueta
   if (userMessage.mediaType === 'image') {
     return `[Imagen] ${userMessage.text || ''}`.trim();
   } else if (userMessage.mediaType === 'audio') {
     return `[Audio] ${userMessage.text || ''}`.trim();
   }
   
   // Detección de formato WhatsApp en bruto
   if (userMessage.message) {
     if (userMessage.message.imageMessage) {
       const caption = userMessage.message.imageMessage.caption || '';
       return `[Imagen] ${caption}`.trim();
     }
     if (userMessage.message.audioMessage) {
       return '[Audio]';
     }
   }
   
   return userMessage.text || userMessage.content || userMessage.message || 'No hay mensajes';
 }, []);

 const isTestMessage = useCallback((message) => {
   if (!message) return false;
   return /^prueba[0-9a-f]+$/i.test(message);
 }, []);

 // Función para formatear un número de teléfono para búsqueda
 const formatPhoneNumber = useCallback((phoneNumber) => {
   // Si ya tiene prefijo 34, lo dejamos igual
   if (phoneNumber.startsWith('34')) {
     return phoneNumber;
   }
   // De lo contrario, agregamos el prefijo
   return `34${phoneNumber}`;
 }, []);

 // Función para verificar si hay un job incompleto
 const checkJobStatus = useCallback(async () => {
   if (!userId) return;
   
   try {
     setCheckingJob(true);
     const status = await JobsService.checkJobStatus(userId);
     
     // Solo establecer el estado si el job existe y no está completado
     if (status.exists && status.job.status !== "completed") {
       setJobStatus(status);
       console.log('Estado del job:', status);
     } else {
       // Si el job está completado o no existe, limpiamos el estado
       setJobStatus(null);
       console.log('No hay jobs activos o pendientes');
     }
   } catch (error) {
     console.error('Error al verificar el estado del job:', error);
     showNotification('Error al verificar el estado del job masivo', 'error');
   } finally {
     setCheckingJob(false);
   }
 }, [userId, showNotification]);

 // Función para reanudar un job
 const handleResumeJob = useCallback(async () => {
   if (!userId || !jobStatus?.canResume) return;
   
   try {
     setCheckingJob(true);
     showNotification('Reanudando job masivo...', 'info');
     
     const result = await JobsService.resumeJob(userId);
     
     showNotification(`Job reanudado exitosamente: ${result.message}`, 'success');
     
     // Actualizamos el estado después de reanudar
     setTimeout(() => {
       checkJobStatus();
     }, 1000);
   } catch (error) {
     console.error('Error al reanudar el job:', error);
     showNotification(`Error al reanudar el job: ${error.message || 'Ocurrió un problema'}`, 'error');
   } finally {
     setCheckingJob(false);
   }
 }, [userId, jobStatus, showNotification, checkJobStatus]);

 // Función para cargar datos
 const fetchData = useCallback(async () => {
   if (!userId) return;
   try {
     setLoading(true);
     let newResponses;
     let paginationData = null;
     
     // Si hay un término de búsqueda específico por número de teléfono
     if (searchTerm.trim() !== '') {
       try {
         // Formateamos el número para incluir el prefijo 34 si no lo tiene
         const formattedPhoneNumber = formatPhoneNumber(searchTerm.trim());
         
         // Usar el servicio específico de búsqueda por teléfono
         const searchResult = await MessageService.searchChatByPhone(userId, formattedPhoneNumber);
         console.log('Usando MessageService.searchChatByPhone');
         console.log('Respuesta de búsqueda:', searchResult);
         
         if (searchResult && searchResult.data) {
           // Procesamos correctamente el resultado de la búsqueda
           const chatData = searchResult.data;
           
           // Procesar el historial de mensajes para manejar media
           const historiasProcesadas = procesarHistorialMensajes(chatData.summarizedHistory || []);
           
           // Ordenar el historial por timestamp
           const sortedHistory = historiasProcesadas.sort((a, b) => {
             const timeA = new Date(a.timestamp).getTime();
             const timeB = new Date(b.timestamp).getTime();
             return timeA - timeB;
           });
           
           // Creamos un objeto con la estructura esperada por el componente
           newResponses = [{
             phoneNumber: chatData.phoneNumber,
             createAt: chatData.createAt,
             id: chatData.conversationId,
             chatId: chatData.chatId,
             interes: chatData.interes || 'Nuevo',
             lastMessage: chatData.lastMessage ? chatData.lastMessage.text : '',
             summarizedHistory: sortedHistory,
             // Añadimos una copia completa de los datos para poder acceder a ellos después
             _rawData: chatData
           }];
           
           console.log('Datos procesados para mostrar:', newResponses);
         } else {
           console.log('No se encontraron datos o estructura incorrecta');
           newResponses = [];
           showNotification(`No se encontraron conversaciones con el número: ${searchTerm}`, 'info');
         }
       } catch (error) {
         console.error('Error en búsqueda por teléfono:', error);
         newResponses = [];
         if (error.response && error.response.status === 404) {
           showNotification(`No se encontró ninguna conversación con el número: ${searchTerm}. Intenta incluyendo el prefijo 34 si es necesario.`, 'info');
         } else {
           showNotification(`Error al buscar el número: ${error.message}`, 'error');
         }
       }
     } else if (!(timeFilter === 'all' && interesFilter === 'all')) {
       // Endpoint con paginación para filtros de tiempo e interés
       const res = await MessageService.getRecentChats(userId, page, limit, false);
       
       // Procesar cada chat para manejar correctamente los mensajes multimedia
       if (res.data && Array.isArray(res.data)) {
         newResponses = res.data.map(chat => {
           if (chat.summarizedHistory) {
             // Procesar y ordenar el historial de mensajes
             const historiasProcesadas = procesarHistorialMensajes(chat.summarizedHistory);
             chat.summarizedHistory = historiasProcesadas.sort((a, b) => {
               const timeA = new Date(a.timestamp).getTime();
               const timeB = new Date(b.timestamp).getTime();
               return timeA - timeB;
             });
           }
           return chat;
         });
       } else {
         newResponses = res.data;
       }
       
       paginationData = res.pagination;
       console.log('Usando MessageService.getRecentChats');
       setShowPagination(true);
     } else {
       // Endpoint sin paginación: pending responses (filtra por contactado: false)
       const pendingResponses = await getPendingResponses(userId);
       
       // Procesar y ordenar cada chat
       if (Array.isArray(pendingResponses)) {
         newResponses = pendingResponses.map(chat => {
           if (chat.summarizedHistory) {
             // Procesar y ordenar el historial de mensajes
             const historiasProcesadas = procesarHistorialMensajes(chat.summarizedHistory);
             chat.summarizedHistory = historiasProcesadas.sort((a, b) => {
               const timeA = new Date(a.timestamp).getTime();
               const timeB = new Date(b.timestamp).getTime();
               return timeA - timeB;
             });
           }
           return chat;
         });
       } else {
         newResponses = pendingResponses;
       }
       
       console.log('Usando getPendingResponses');
       setShowPagination(false);
     }
     
     lastFetchedFilter.current = { time: timeFilter, interes: interesFilter, search: searchTerm };
     setResponses(Array.isArray(newResponses) ? newResponses : []);
     if (paginationData) setPagination(paginationData);
   } catch (error) {
    console.error('Error fetching data:', error);
    showNotification('Error al cargar los datos', 'error');
    setResponses([]);
  } finally {
    setLoading(false);
  }
}, [userId, timeFilter, interesFilter, searchTerm, page, limit, showNotification, formatPhoneNumber, procesarHistorialMensajes]);

useEffect(() => { setPage(1); }, [timeFilter, interesFilter, searchTerm]);

useEffect(() => {
  if (!userId) { navigate('/'); return; }
  if (isInitialMount.current) { 
    isInitialMount.current = false; 
    fetchData();
    checkJobStatus(); // Verificar estado de jobs al cargar
    return; 
  }
  const timeoutId = setTimeout(() => { fetchData(); }, 300);
  return () => clearTimeout(timeoutId);
}, [userId, navigate, fetchData, checkJobStatus]);

// Agrupar y filtrar las respuestas
const filteredResponses = useMemo(() => {
  if (!responses.length) return [];
  
  // Si estamos buscando por número de teléfono y ya tenemos los datos procesados
  if (searchTerm.trim() !== '' && responses.length === 1 && responses[0]._rawData) {
    // Crear un objeto con la estructura esperada para el componente ChatElement
    const chatData = responses[0];
    const result = {
      [chatData.phoneNumber]: {
        phoneNumber: chatData.phoneNumber,
        conversation: {
          ...chatData,
          // Asegurarnos de que chatId e id estén disponibles
          chatId: chatData.chatId,
          id: chatData.id || chatData.conversationId
        },
        lastMessage: chatData.lastMessage,
        createAt: chatData.createAt,
        interes: chatData.interes || 'Nuevo'
      }
    };
    console.log('Datos para búsqueda por teléfono:', result);
    return result;
  }
  
  return responses
    .filter(conversation => {
      // Verificar si es un mensaje de prueba
      if (conversation.lastMessage) {
        if (isTestMessage(conversation.lastMessage.text)) return false;
      } else {
        const lastMsg = getLastMessageFromHistory(conversation);
        if (isTestMessage(lastMsg)) return false;
      }
      
      // Verificar que hay al menos 2 mensajes si summarizedHistory existe
      if (conversation.summarizedHistory) {
        return conversation.summarizedHistory.length >= 2;
      }
      return true; // Si no hay summarizedHistory, lo incluimos y dejamos que otros filtros decidan
    })
    .reduce((grouped, conversation) => {
      const phone = conversation.phoneNumber;
      
      // Obtener el texto del último mensaje
      const lastMessageText = conversation.lastMessage 
        ? (typeof conversation.lastMessage === 'string' ? conversation.lastMessage : conversation.lastMessage.text) 
        : getLastMessageFromHistory(conversation);
      
      // Asegurarnos de que createAt sea una fecha válida
      let createAt = conversation.createAt;
      if (typeof createAt === 'string' && !createAt.includes('T')) {
        // Si no es un formato ISO, convertirlo
        try {
          createAt = new Date(createAt).toISOString();
        } catch (e) {
          console.error('Error al convertir createAt:', e, createAt);
          createAt = new Date().toISOString(); // Usar fecha actual como fallback
        }
      }
      
      if (!grouped[phone]) {
        grouped[phone] = {
          phoneNumber: phone,
          conversation,
          lastMessage: lastMessageText,
          createAt: createAt,
          interes: conversation.interes || 'Nuevo'
        };
      } else {
        // Comparar fechas correctamente
        let currentDate, newDate;
        
        try {
          currentDate = new Date(grouped[phone].createAt);
          newDate = new Date(createAt);
        } catch (e) {
          console.error('Error al convertir fechas para comparación:', e);
          // Usar timestamp actual como fallback
          currentDate = new Date();
          newDate = new Date();
        }
        
        // Reemplazar solo si es más reciente
        if (newDate > currentDate) {
          grouped[phone] = {
            phoneNumber: phone,
            conversation,
            lastMessage: lastMessageText,
            createAt: createAt,
            interes: conversation.interes || 'Nuevo'
          };
        }
      }
      return grouped;
    }, {});
}, [responses, getLastMessageFromHistory, isTestMessage, searchTerm]);

const finalFilteredResponses = useMemo(() => {
  if (!Object.keys(filteredResponses).length) return [];
  
  // Si estamos buscando por número de teléfono, devolvemos los resultados directamente
  if (searchTerm.trim() !== '') {
    return Object.values(filteredResponses);
  }
  
  // Si se usan pending responses (sin filtros adicionales) se retornan todos
  if (timeFilter === 'all' && interesFilter === 'all') {
    return Object.values(filteredResponses);
  }
  
  // Aplicar filtros de tiempo e interés
  return Object.values(filteredResponses).filter(item => {
    const currentTime = new Date();
    let lastUpdateTime;
    
    try {
      lastUpdateTime = new Date(item.createAt);
      // Verificar si la fecha es válida
      if (isNaN(lastUpdateTime.getTime())) {
        console.warn('Fecha inválida en finalFilteredResponses:', item.createAt);
        lastUpdateTime = new Date(); // Usar fecha actual como fallback
      }
    } catch (e) {
      console.error('Error al convertir fecha en finalFilteredResponses:', e, item.createAt);
      lastUpdateTime = new Date(); // Usar fecha actual como fallback
    }
    
    const hoursDiff = (currentTime - lastUpdateTime) / (1000 * 60 * 60);
    let passesTimeFilter = true;
    switch (timeFilter) {
      case 'last24': passesTimeFilter = hoursDiff < 24; break;
      case 'last72': passesTimeFilter = hoursDiff >= 24 && hoursDiff < 72; break;
      case 'older72': passesTimeFilter = hoursDiff >= 72; break;
      default: passesTimeFilter = true;
    }
    let passesInteresFilter = true;
    if (interesFilter !== 'all') { passesInteresFilter = (item.interes === interesFilter); }
    return passesTimeFilter && passesInteresFilter;
  });
}, [filteredResponses, timeFilter, interesFilter, searchTerm]);

// Cambio: Ahora solo actualizamos el campo de entrada sin disparar la búsqueda
const handleSearchChange = useCallback((event) => {
  setInputSearchTerm(event.target.value);
}, []);

// Nueva función para manejar el clic en el botón de búsqueda
const handleSearchClick = useCallback(() => {
  // Solo realizar la búsqueda si hay algo que buscar
  if (inputSearchTerm.trim()) {
    setSearchTerm(inputSearchTerm);
    // Cuando buscamos por teléfono, no mostramos paginación ya que usamos searchChatByPhone
    setShowPagination(false);
  }
}, [inputSearchTerm]);

// Maneja la tecla Enter en el campo de búsqueda
const handleSearchKeyPress = useCallback((event) => {
  if (event.key === 'Enter' && !loading && inputSearchTerm.trim()) {
    handleSearchClick();
    event.preventDefault();
  }
}, [handleSearchClick, loading, inputSearchTerm]);

const handleTimeFilterChange = useCallback(event => { 
  const newValue = event.target.value;
  setTimeFilter(newValue); 
  // Actualizar el estado de paginación cuando cambia el filtro de tiempo
  setShowPagination(!(newValue === 'all' && interesFilter === 'all'));
  // Limpiar búsqueda por teléfono cuando se cambia el filtro de tiempo
  if (searchTerm.trim() !== '') {
    setSearchTerm('');
    setInputSearchTerm('');
  }
}, [interesFilter, searchTerm]);

const handleInteresFilterChange = useCallback(event => { 
  const newValue = event.target.value;
  setInteresFilter(newValue); 
  // Actualizar el estado de paginación cuando cambia el filtro de interés
  setShowPagination(!(timeFilter === 'all' && newValue === 'all'));
  // Limpiar búsqueda por teléfono cuando se cambia el filtro de interés
  if (searchTerm.trim() !== '') {
    setSearchTerm('');
    setInputSearchTerm('');
  }
}, [timeFilter, searchTerm]);

const handleClearSearch = useCallback(() => { 
  setInputSearchTerm(''); 
  setSearchTerm('');
  // Actualizar el estado de paginación cuando se limpia la búsqueda
  setShowPagination(!(timeFilter === 'all' && interesFilter === 'all'));
}, [timeFilter, interesFilter]);

const handleRefresh = useCallback(() => { 
  fetchData();
  checkJobStatus(); // También refrescar estado de los jobs
}, [fetchData, checkJobStatus]);

const handleModeChange = useCallback(async () => {
 try {
   const newMode = !isAutonomous;
   await setAutonomousMode(userId, newMode);
   setIsAutonomous(newMode);
   showNotification(`Autonomous mode ${newMode ? 'enabled' : 'disabled'}`);
 } catch (error) {
   showNotification('Error changing mode', 'error');
   console.error('Error changing mode:', error);
 }
}, [isAutonomous, userId, showNotification]);

const handleSendMasive = useCallback(() => {
 navigate('/sendWsMasive', { state: { userId } });
}, [navigate, userId]);

// Nuevo manejador para ir al panel de estadísticas
const handleViewAnalytics = useCallback(() => {
 console.log("Navegando a analytics con userSessionId:", userId);
 navigate('/campaign-analytics', { state: { userSessionId: userId } });
}, [navigate, userId]);


// Manejador para cancelar un job
const handleCancelJob = useCallback(async () => {
if (!userId || !jobStatus?.exists) return;

// Mostrar un diálogo de confirmación antes de cancelar
if (!window.confirm('¿Estás seguro de que deseas cancelar este envío masivo? Esta acción no se puede deshacer.')) {
  return;
}

try {
  setCheckingJob(true);
  showNotification('Cancelando job masivo...', 'info');
  
  const response = await JobsService.cancelJob(userId);
  
  showNotification('Job cancelado exitosamente', 'success');
  
  // Actualizamos el estado después de cancelar
  setTimeout(() => {
    checkJobStatus();
  }, 1000);
} catch (error) {
  console.error('Error al cancelar el job:', error);
  showNotification(`Error al cancelar el job: ${error.message || 'Ocurrió un problema'}`, 'error');
} finally {
  setCheckingJob(false);
}
}, [userId, jobStatus, showNotification, checkJobStatus]);

// Función para manejar la reconexión
const handleReconnect = useCallback(async () => {
 if (!userId) return;
 
 try {
   setIsReconnecting(true);
   showNotification('Desconectando', 'info');
   
   await reconnectClient(userId);
   
   showNotification('El bot se ha desconectado', 'success');
   
   // Opcional: navegar a la página del código QR o refrescar los datos
   // navigate('/qr', { state: { userId } });
   
   // Alternativamente, puedes refrescar los datos después de un breve retraso
   setTimeout(() => {
     fetchData();
   }, 3000);
   
 } catch (error) {
   console.error('Error al reconectar:', error);
   showNotification(`Error al reconectar: ${error.message || 'Ocurrió un problema'}`, 'error');
 } finally {
   setIsReconnecting(false);
 }
}, [userId, showNotification, fetchData]);

const handleLogout = useCallback(async () => {
 try {
   await logoutUser();
   showNotification('Successfully logged out');
   navigate('/');
 } catch (error) {
   showNotification('Error logging out. Please try again', 'error');
   console.error('Error in handleLogout:', error);
 }
}, [logoutUser, navigate, showNotification]);

const handlePreviousPage = () => { if (page > 1) setPage(prev => prev - 1); };
const handleNextPage = () => { if (pagination && pagination.hasMore) setPage(prev => prev + 1); };

// Verificar si hay un job activo para mostrar (no completado)
const hasActiveJob = useMemo(() => {
 return jobStatus && jobStatus.exists && jobStatus.job.status !== "completed";
}, [jobStatus]);

return (
 <>
   <Container>
     <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', justifyContent: 'space-between', alignItems: isSmallScreen ? 'stretch' : 'center', mb: 3, gap: 2 }}>
       <Title variant={isSmallScreen ? 'h5' : 'h4'}>Pending GPT Responses</Title>
       <ButtonsContainer>
         <Button variant="contained" onClick={handleRefresh} color="primary" fullWidth={isSmallScreen} startIcon={<RefreshIcon />} disabled={loading}>
           {loading ? 'Refreshing...' : 'Refresh Responses'}
         </Button>
         <Button variant="contained" onClick={handleModeChange} color="primary" fullWidth={isSmallScreen}>
           {isAutonomous ? 'Disable Autonomous' : 'Enable Autonomous'}
         </Button>
         {/* Botón de pausar */}
         <Button 
           variant="contained" 
           onClick={handleReconnect} 
           color="warning" 
           fullWidth={isSmallScreen} 
           startIcon={<PauseIcon />} 
           disabled={isReconnecting}
         >
           {isReconnecting ? 'Desconectando...' : 'Desconectar BOT'}
         </Button>
         <Button variant="contained" onClick={handleSendMasive} color="primary" fullWidth={isSmallScreen}>
           Send Bulk Messages
         </Button>
         <Button 
           variant="contained" 
           onClick={handleViewAnalytics} 
           color="primary" 
           fullWidth={isSmallScreen}
           startIcon={<AssessmentIcon />}
         >
           Análisis de Campañas
         </Button>
         {/* Botón para reanudar job masivo (solo visible cuando hay un job que se puede reanudar) */}
         {jobStatus && jobStatus.exists && jobStatus.canResume && (
           <Button 
             variant="contained" 
             onClick={handleResumeJob} 
             color="success" 
             fullWidth={isSmallScreen}
             disabled={checkingJob}
             startIcon={<PlayArrowIcon />}
           >
             {checkingJob ? 'Reanudando...' : 'Reanudar Envío Masivo'}
           </Button>
         )}
         <Button variant="outlined" onClick={handleLogout} color="secondary" fullWidth={isSmallScreen}>
           Logout
         </Button>
       </ButtonsContainer>
     </Box>

     {/* Información de jobs (solo si hay un job activo o pausado, no completado) */}
     {hasActiveJob && (
       <Box sx={{ 
         display: 'flex', 
         flexDirection: 'column', 
         mt: 2, 
         mb: 3,
         p: 2, 
         bgcolor: 'background.paper', 
         borderRadius: 1,
         border: '1px solid',
        borderColor: 'divider'
      }}>
        {/* Título del job con su estado */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="subtitle1" fontWeight="bold">
            Envío masivo: {jobStatus.job.campaignName || 'Sin nombre'}
          </Typography>
          <Typography variant="caption" sx={{ 
            bgcolor: jobStatus.activeInMemory ? '#4caf50' : 
                    (jobStatus.canResume ? '#ff9800' : '#9e9e9e'), 
            color: 'white', 
            px: 1, 
            py: 0.5, 
            borderRadius: 1 
          }}>
            {jobStatus.activeInMemory ? 'En progreso' : 
             (jobStatus.canResume ? 'Pausado' : jobStatus.job.status)}
          </Typography>
        </Box>
        
        {/* Barra de progreso */}
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress 
              variant="determinate" 
              value={jobStatus.job.progress.percentage} 
              sx={{ height: 10, borderRadius: 5 }}
            />
          </Box>
          <Typography variant="body2" color="text.secondary">
            {jobStatus.job.progress.percentage}%
          </Typography>
        </Box>
        
        {/* Estadísticas */}
        <Typography variant="body2" color="text.secondary" mt={1}>
          Enviados: {jobStatus.job.progress.sent} / Fallidos: {jobStatus.job.progress.failed} / Total: {jobStatus.job.progress.total}
        </Typography>
        
        {/* Botones de acción según el estado */}
        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
          {/* Botón para reanudar (solo si se puede reanudar) */}
          {jobStatus.canResume && (
            <Button 
              variant="contained" 
              onClick={handleResumeJob} 
              color="success" 
              startIcon={<PlayArrowIcon />}
              disabled={checkingJob}
            >
              {checkingJob ? 'Reanudando...' : 'Reanudar envío'}
            </Button>
          )}
          
          {/* Botón para cancelar (solo si está activo o puede reanudarse) */}
          {(jobStatus.activeInMemory || jobStatus.canResume) && (
            <Button 
              variant="contained" 
              onClick={handleCancelJob} 
              color="error" 
              startIcon={<CancelIcon />}
              disabled={checkingJob}
            >
              Cancelar envío
            </Button>
          )}
        </Box>
      </Box>
    )}

    <FilterContainer>
      <FormControl sx={{ minWidth: 200, flex: 1 }}>
        <InputLabel id="time-filter-label">Filtrar por tiempo</InputLabel>
        <Select labelId="time-filter-label" id="time-filter" value={timeFilter} label="Filtrar por tiempo" onChange={handleTimeFilterChange} size="small" disabled={loading}>
          <MenuItem value="all">Pendientes de gestionar</MenuItem>
          <MenuItem value="last24">Últimas 24 horas</MenuItem>
          <MenuItem value="last72">Entre 24 y 72 horas</MenuItem>
          <MenuItem value="older72">Más de 72 horas</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ minWidth: 200, flex: 1 }}>
        <InputLabel id="interes-filter-label">Filtrar por interés</InputLabel>
        <Select labelId="interes-filter-label" id="interes-filter" value={interesFilter} label="Filtrar por interés" onChange={handleInteresFilterChange} size="small" disabled={loading || timeFilter === 'all'}>
          <MenuItem value="all">Todos</MenuItem>
          {opcionesInteres.map(opcion => (
           <MenuItem key={opcion} value={opcion}>{opcion}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="Buscar por número"
        variant="outlined"
        size="small"
        value={inputSearchTerm}
        onChange={handleSearchChange}
        onKeyPress={handleSearchKeyPress}
        sx={{ flex: 2 }}
        disabled={loading}
        helperText={searchTerm.trim() ? "" : "Puedes buscar con o sin prefijo (34)"}
        InputProps={{
          startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>),
          endAdornment: (
            <InputAdornment position="end">
              {inputSearchTerm && (
                <IconButton aria-label="clear search" onClick={handleClearSearch} edge="end" size="small" disabled={loading}>
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton 
                aria-label="search" 
                onClick={handleSearchClick} 
                edge="end" 
                size="small" 
                disabled={loading || !inputSearchTerm.trim()}
                color="primary"
                sx={{ ml: 0.5 }}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </FilterContainer>

    {loading ? (
      <LoadingWrapper>
        <CircularProgress size={40} thickness={4} />
      </LoadingWrapper>
    ) : finalFilteredResponses.length === 0 ? (
      <EmptyStateMessage variant="body1">
        No hay conversaciones que coincidan con los filtros aplicados.
      </EmptyStateMessage>
    ) : (
      <ConversationsContainer>
        {finalFilteredResponses.length <= 100 ? (
          finalFilteredResponses.map((item) => (
            <ChatElement
              key={item.phoneNumber}
              phoneNumber={item.phoneNumber}
              lastMessage={item.lastMessage}
              createAt={item.createAt}
              userId={userId}
              conversation={item.conversation}
              navigate={navigate}
              interes={item.interes}
            />
          ))
        ) : (
          <List height={600} width="100%" itemCount={finalFilteredResponses.length} itemSize={80}>
            {({ index, style }) => {
              const item = finalFilteredResponses[index];
              return (
                <div style={style}>
                  <ChatElement
                    phoneNumber={item.phoneNumber}
                    lastMessage={item.lastMessage}
                    createAt={item.createAt}
                    userId={userId}
                    conversation={item.conversation}
                    navigate={navigate}
                    interes={item.interes}
                  />
                </div>
              );
            }}
          </List>
        )}
      </ConversationsContainer>
    )}

    {pagination && showPagination && (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2, gap: 2 }}>
        <Button variant="outlined" onClick={handlePreviousPage} disabled={page === 1 || loading}>
          Página Anterior
        </Button>
        <Typography>
          Página {page} de {pagination.totalPages}
        </Typography>
        <Button variant="outlined" onClick={handleNextPage} disabled={!pagination.hasMore || loading}>
          Página Siguiente
        </Button>
      </Box>
    )}
   </Container>

   <Snackbar
     open={snackbar.open}
     autoHideDuration={SNACKBAR_DURATION}
     onClose={() => setSnackbar({ ...snackbar, open: false })}
     anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
   >
     <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} elevation={6} variant="filled">
       {snackbar.message}
     </Alert>
   </Snackbar>
 </>
);
};

export default PendingResponses;