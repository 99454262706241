// src/utils/config.js

// URLs base para cada dominio
const API_BASE_URLS = {
    'apiwsprojectfrontend.web.app': 'https://apiwsproject-latest-zu9g.onrender.com/api',
    'apiwsproject.com': 'https://apiwsproject-latest-zu9g.onrender.com/api',  // Dominio personalizado
    'wspito-project.web.app': 'https://apiwsproject-ws-pj.onrender.com/api',
    'wspito.com': 'https://apiwsproject-ws-pj.onrender.com/api',  // Dominio personalizado
    'localhost': 'http://localhost:4001/api'
  };
  
  // URLs base sin el sufijo /api
  const API_ROOT_URLS = {
    'apiwsprojectfrontend.web.app': 'https://apiwsproject-latest-zu9g.onrender.com',
    'apiwsproject.com': 'https://apiwsproject-latest-zu9g.onrender.com',  // Dominio personalizado
    'wspito-project.web.app': 'https://apiwsproject-ws-pj.onrender.com',
    'wspito.com': 'https://apiwsproject-ws-pj.onrender.com',  // Dominio personalizado
    'localhost': 'http://localhost:4001'
  };
  
  // Determinar el dominio actual
  const getCurrentDomain = () => {
    const hostname = window.location.hostname;
    
    // Comprobación exacta primero
    if (API_BASE_URLS[hostname]) {
      return hostname;
    }
    
    // Comprobar localhost para desarrollo
    if (hostname.includes('localhost') || hostname === '127.0.0.1') {
      return 'localhost';
    }
    
    // Comprobar qué proyecto basado en el nombre de dominio
    if (hostname.includes('wspito')) {
      return 'wspito-project.web.app';
    }
    
    // Predeterminado a apiwsproject
    return 'apiwsprojectfrontend.web.app';
  };
  
  // Determinar el dominio activo
  const activeDomain = getCurrentDomain();
  
  // Exportar URLs base con y sin /api
  export const API_BASE_URL = API_BASE_URLS[activeDomain];
  export const API_ROOT = API_ROOT_URLS[activeDomain];
  
  // Endpoints específicos para cada servicio
  export const ENDPOINTS = {
    // Servicios principales
    USER_SESSIONS: `${API_BASE_URL}/userSessions`,
    USERS: `${API_BASE_URL}/users`,
    SESSIONS: `${API_BASE_URL}/sessions`,
    
    // Servicios de mensajería
    MESSAGES: `${API_BASE_URL}/messages`,
    CHATS: `${API_BASE_URL}/chats`,
    QR: `${API_BASE_URL}/qr`,
    
    // Servicios de negocio
    CHECKOUT: `${API_BASE_URL}/checkout`,
    CAMPAIGN_ANALYTICS: `${API_BASE_URL}/campaign-analytics`,
    SEGUIMIENTO: `${API_BASE_URL}/seguimiento`,
    JOBS: `${API_BASE_URL}/jobs`
  };
  
  // Información de la app y entorno
  export const APP_CONFIG = {
    VERSION: '1.0.0',
    ENVIRONMENT: process.env.NODE_ENV,
    IS_PRODUCTION: process.env.NODE_ENV === 'production',
    ACTIVE_DOMAIN: activeDomain
  };
  
  // Para debugging
  console.log(`API configurada para: ${window.location.hostname} -> ${API_BASE_URL}`);
  console.log(`Entorno: ${APP_CONFIG.ENVIRONMENT}, Dominio activo: ${activeDomain}`);