import axios from 'axios';
import { ENDPOINTS } from '../utils/config';

const API_URL = ENDPOINTS.JOBS;

const JobsService = {
  // Verificar el estado de un job
  checkJobStatus: async (userSessionId) => {
    console.log(`Realizando petición a: ${API_URL}/${userSessionId}/status`);
    try {
      const response = await axios.get(`${API_URL}/${userSessionId}/status`);
      console.log('Respuesta del servidor:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error en la petición checkJobStatus:', error);
      throw error.response ? error.response.data : error;
    }
  },

  // Método corregido para cancelar un job
  cancelJob: async (userSessionId) => {
    console.log(`Realizando petición a: ${API_URL}/${userSessionId}/cancel`);
    try {
      const response = await axios.post(`${API_URL}/${userSessionId}/cancel`);
      console.log('Respuesta cancelJob:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error en cancelJob:', error);
      throw error.response ? error.response.data : error;
    }
  },

  // Reanudar un job
  resumeJob: async (userSessionId) => {
    console.log(`Realizando petición a: ${API_URL}/${userSessionId}/resume`);
    try {
      const response = await axios.post(`${API_URL}/${userSessionId}/resume`);
      console.log('Respuesta resumeJob:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error en resumeJob:', error);
      throw error.response ? error.response.data : error;
    }
  }
};

export default JobsService;