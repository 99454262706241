// services/session.js
import axios from 'axios';
import { ENDPOINTS } from '../utils/config';

const API_URL = ENDPOINTS.SESSIONS;

const SessionService = {
  // Crea o actualiza la sesión con { userId, agentId }
  createSession: async (sessionData) => {
    try {
      // POST /api/sessions
      const response = await axios.post(API_URL, sessionData);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  // Lista todas las sesiones
  getAllSessions: async () => {
    try {
      const response = await axios.get(API_URL);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  // Actualiza una sesión (p.ej. agentId, isAutonomous, etc.)
  updateSession: async (id, sessionData) => {
    try {
      const response = await axios.put(`${API_URL}/${id}`, sessionData);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  // Elimina una sesión
  deleteSession: async (id) => {
    try {
      const response = await axios.delete(`${API_URL}/${id}`);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },
};

export default SessionService;