import axios from 'axios';
import { API_BASE_URL } from '../utils/config';

// Función para obtener el código QR
export const getQRCode = async (userId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/qr/${userId}`);
    return response.data;
  } catch (error) {
    // Puedes personalizar el manejo de errores aquí si es necesario
    throw error.response ? error.response.data : error;
  }
};

// Función para obtener respuestas pendientes
export const getPendingResponses = async (userId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/messages/${userId}/pendingResponse`);
    return response.data.responses;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

// Función para reconectar cliente WhatsApp
export const reconnectClient = async (userId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/qr/${userId}/reconnect`);
    return response.data;
  } catch (error) {
    console.error('Error al reconectar cliente:', error);
    throw error.response ? error.response.data : error;
  }
};

// Función para enviar respuesta validada
export const sendValidatedResponse = async (userId, validatedResponse, messageId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/messages/${userId}/validateResponse`, {
      validatedResponse,
      messageId, // Ensure messageId is sent here
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

// Función para enviar mensaje manual
export const sendManualMessage = async (userId, phoneNumber, messageData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/messages/send-manual-message`, {
      userId,
      phoneNumber,
      message: messageData // Puede ser un string o un objeto con text, mediaData y mediaMimetype
    });
    return response.data;
  } catch (error) {
    console.error('Error enviando mensaje manual:', error);
    throw error.response ? error.response.data : error;
  }
};

// Función para verificar estado de sesión
export const checkSessionStatus = async (userId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/qr/${userId}/sessionStatus`);
    return response.data; // Devuelve toda la información relevante del cliente
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

// Función para establecer modo autónomo
export const setAutonomousMode = async (userId, isAutonomous) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/messages/${userId}/setMode`, {
      isAutonomous,
    });

    return response.data; // Successfully updated mode
  } catch (error) {
    console.error('Error setting autonomous mode:', error.response?.data || error.message);
    throw error;
  }
};

// Función para eliminar mensaje
export const deleteMessage = async (messageId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/messages/${messageId}`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

// Función para marcar todos los mensajes de un chat como enviados
export const markAllMessagesAsSent = async (userSessionId, chatId) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/messages/${userSessionId}/markAllAsSent`, {
      chatId
    });
    return response.data;
  } catch (error) {
    console.error('Error al marcar mensajes como enviados:', error);
    throw error.response ? error.response.data : error;
  }
};

// Función para enviar masivo CSV
export const enviarMasivoCSV = async (userId, selectedFile, campaignName) => {
  const formData = new FormData();
  formData.append('file', selectedFile, selectedFile.name);
  formData.append('campaignName', campaignName);  // Agregamos el nombre de la campaña

  try {
    const response = await axios.post(`${API_BASE_URL}/messages/${userId}/enviarMasivo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error enviando archivo CSV:', error.response?.data || error.message);
    throw error;
  }
};


// Función para limpiar completamente los datos de un usuario
export const cleanupAllUserData = async (userId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/qr/${userId}/cleanupAll`);
    return response.data;
  } catch (error) {
    console.error('Error al limpiar datos del usuario:', error);
    throw error.response ? error.response.data : error;
  }
};
