import React, { useEffect, useState, useCallback } from 'react';
import { 
  Typography, CircularProgress, Box, Snackbar, Alert,
  Button, Card, CardContent, Grid, Divider, Chip,
  Paper, Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, TablePagination, Accordion,
  AccordionSummary, AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useGlobal } from '../context/globalContex';
import CampaignAnalyticsService from '../services/campaignAnalytics';

// Estilos
const Container = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4), 
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4), 
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', 
  maxHeight: '80vh', 
  overflowY: 'auto'
}));

const Title = styled(Typography)(({ theme }) => ({ 
  color: theme.palette.text.primary, 
  marginBottom: theme.spacing(3), 
  fontWeight: 600 
}));

const LoadingWrapper = styled(Box)({ 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
  minHeight: '200px' 
});

const SNACKBAR_DURATION = 6000;

const getStatusChip = (classification) => {
  let color = 'default';
  if (classification === 'positive') color = 'success';
  if (classification === 'negative') color = 'error';
  if (classification === 'neutral') color = 'info';
  
  return (
    <Chip 
      label={classification || 'No clasificado'} 
      color={color}
      size="small"
    />
  );
};

const CampaignDetails = () => {
  // Estados 
  const [campaignData, setCampaignData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Hooks
  const { jobId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = useGlobal();
  const { user } = state;
  const userId = location.state?.userId || user?.id;

  // Responsividad
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const showNotification = useCallback((message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  }, []);

  const fetchCampaignDetails = useCallback(async () => {
    if (!jobId || !userId) return;
    try {
      setLoading(true);
      const response = await CampaignAnalyticsService.getCampaignAnalytics(jobId, userId);
      if (response.success) {
        setCampaignData(response.analytics);
      } else {
        showNotification('Error al cargar detalles de la campaña', 'error');
      }
    } catch (error) {
      console.error('Error fetching campaign details:', error);
      showNotification('Error al cargar detalles de la campaña', 'error');
    } finally {
      setLoading(false);
    }
  }, [jobId, userId, showNotification]);

  useEffect(() => {
    if (!userId) {
      navigate('/');
      return;
    }
    fetchCampaignDetails();
  }, [userId, jobId, navigate, fetchCampaignDetails]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const formatPhoneNumber = (phoneNumber) => {
    return phoneNumber ? phoneNumber.replace('@s.whatsapp.net', '') : 'N/A';
  };

  const handleBackToAnalytics = () => {
    navigate('/campaign-analytics', { state: { userSessionId: userId } });
  };

  const handleViewChat = (phoneNumber) => {
    const chatId = `${phoneNumber}@s.whatsapp.net`;
    navigate('/chat', { state: { userId, conversationId: chatId } });
  };

  return (
    <>
      <Container>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <Button 
            startIcon={<ArrowBackIcon />} 
            onClick={handleBackToAnalytics}
            sx={{ mr: 2 }}
          >
            Volver
          </Button>
          <Title variant={isSmallScreen ? 'h5' : 'h4'}>
            Detalles de Campaña
          </Title>
        </Box>

        {loading ? (
          <LoadingWrapper>
            <CircularProgress size={40} thickness={4} />
          </LoadingWrapper>
        ) : campaignData ? (
          <>
            <Box mb={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {campaignData.campaignName}
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="body2" color="textSecondary">Fecha de inicio</Typography>
                      <Typography variant="body1">{formatDate(campaignData.startDate)}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="body2" color="textSecondary">Mensajes enviados</Typography>
                      <Typography variant="body1">{campaignData.metrics.totalSent}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="body2" color="textSecondary">Respuestas recibidas</Typography>
                      <Typography variant="body1">{campaignData.metrics.totalResponses}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="body2" color="textSecondary">Tasa de respuesta</Typography>
                      <Typography variant="body1">
                        {campaignData.advancedMetrics?.responseRate?.toFixed(1)}%
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="body2" color="textSecondary">Respuestas positivas</Typography>
                      <Typography variant="body1">{campaignData.metrics.positiveResponses}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="body2" color="textSecondary">Respuestas negativas</Typography>
                      <Typography variant="body1">{campaignData.metrics.negativeResponses}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="body2" color="textSecondary">Oportunidades</Typography>
                      <Typography variant="body1">{campaignData.metrics.opportunities}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Typography variant="body2" color="textSecondary">Tiempo medio de respuesta</Typography>
                      <Typography variant="body1">
                        {campaignData.advancedMetrics?.averageResponseTime?.toFixed(1) || 'N/A'} min
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>

            {campaignData.responses && campaignData.responses.length > 0 ? (
              <Box mb={4}>
                <Typography variant="h6" gutterBottom>Respuestas detalladas</Typography>
                <TableContainer component={Paper}>
                  <Table aria-label="tabla de respuestas">
                    <TableHead>
                      <TableRow>
                        <TableCell>Teléfono</TableCell>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Clasificación</TableCell>
                        <TableCell>Oportunidad</TableCell>
                        <TableCell>Tiempo Resp.</TableCell>
                        <TableCell>Mensaje</TableCell>
                        <TableCell>Acciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {campaignData.responses
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((response, index) => (
                          <TableRow key={index}>
                            <TableCell>{formatPhoneNumber(response.phoneNumber)}</TableCell>
                            <TableCell>{response.nombre || 'Sin nombre'}</TableCell>
                            <TableCell>{getStatusChip(response.classification)}</TableCell>
                            <TableCell>
                              <Chip 
                                label={response.aiAnalysis?.isOpportunity ? "Sí" : "No"} 
                                color={response.aiAnalysis?.isOpportunity ? "success" : "default"}
                                size="small"
                              />
                            </TableCell>
                            <TableCell>{response.responseDelay || 'N/A'} min</TableCell>
                            <TableCell>
                              <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                  <Typography variant="body2">
                                    {response.responseContent.substring(0, 30)}
                                    {response.responseContent.length > 30 ? '...' : ''}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography variant="body2">{response.responseContent}</Typography>
                                  {response.aiAnalysis?.explanation && (
                                    <>
                                      <Divider sx={{ my: 1 }} />
                                      <Typography variant="body2" color="textSecondary">
                                        Análisis IA: {response.aiAnalysis.explanation}
                                      </Typography>
                                    </>
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            </TableCell>
                            {/* <TableCell>
                              <Button
                                variant="outlined"
                                size="small"
                                startIcon={<WhatsAppIcon />}
                                onClick={() => handleViewChat(formatPhoneNumber(response.phoneNumber))}
                              >
                                Ver Chat
                              </Button>
                            </TableCell> */}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={campaignData.responses.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Filas por página:"
                  />
                </TableContainer>
              </Box>
            ) : (
              <Typography variant="body1" color="textSecondary" align="center">
                No hay respuestas detalladas disponibles para esta campaña.
              </Typography>
            )}
          </>
        ) : (
          <Typography variant="body1" color="textSecondary" align="center">
            No se encontraron detalles para esta campaña.
          </Typography>
        )}
      </Container>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={SNACKBAR_DURATION}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} elevation={6} variant="filled">
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CampaignDetails;