import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Button,
  Typography,
  Card,
  CardContent,
  Snackbar,
  IconButton,
  Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices'; // Icono de limpieza
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useGlobal } from '../context/globalContex';
import { useTheme } from '@mui/material/styles';
import UserSessionService from '../services/userSessions';
import { cleanupAllUserData } from '../services/client';

const SessionsComponent = () => {
  const { state, addUserSession, updateUserSession } = useGlobal();
  const { user } = state;
  const navigate = useNavigate();
  const theme = useTheme();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleCreateUserSession = async () => {
    console.log('Intentando crear sesión para usuario:', user);

    try {
      const newUserSession = await UserSessionService.createSession({
        userId: user._id,
      });
      
      console.log('Nueva sesión creada:', newUserSession);
      addUserSession(newUserSession);
      
      setSnackbar({
        open: true,
        message: 'New session created successfully',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error detallado al crear sesión:', error);
      setSnackbar({
        open: true,
        message: 'Error creating the session',
        severity: 'error',
      });
    }
  };

  // Función para limpiar completamente los datos
  const handleCleanupSession = async (sessionId, event) => {
    event.stopPropagation();
    console.log('Intentando limpiar completamente sesión:', sessionId);
    
    // Confirmar antes de limpiar
    const confirmMessage = 'ATENCIÓN: Esta acción eliminará TODOS los datos de WhatsApp asociados a esta sesión. Es necesario volver a escanear el código QR. ¿Desea continuar?';
    
    if (!window.confirm(confirmMessage)) {
      return;
    }
    
    try {
      await cleanupAllUserData(sessionId);
      console.log('Datos de sesión limpiados correctamente');
      
      setSnackbar({
        open: true,
        message: 'Session cleaned successfully',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error detallado al limpiar sesión:', error);
      setSnackbar({
        open: true,
        message: 'Error cleaning the session',
        severity: 'error',
      });
    }
  };

  const handleCardClick = (userSession) => {
    console.log('Sesión seleccionada:', userSession);
    
    if (!userSession || !userSession._id) {
      console.error('Error: Datos de sesión inválidos:', userSession);
      setSnackbar({
        open: true,
        message: 'Error: Invalid session data',
        severity: 'error',
      });
      return;
    }

    const navigationState = {userSessionId: userSession._id };
    
    if (userSession.isActive === true) {
      console.log('Navegando a pending-response:', navigationState);
      navigate('/pending-response', { state: navigationState });
    } else {
      console.log('Navegando a selectBot:', navigationState);
      navigate('/ConfigQR', { state: navigationState });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // Verificar estado inicial
  console.log('Estado actual:', {
    user,
    sessions: user?.sessions
  });

  return (
    <Box sx={{ padding: { xs: '10px', sm: '20px' }, overflow: 'hidden' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateUserSession}
            startIcon={<AddIcon />}
            sx={{ 
              marginBottom: '16px',
              width: { xs: '100%', sm: 'auto' },
              padding: { xs: '10px', sm: '6px 16px' },
              minHeight: '48px',
            }}
          >
            Create New Session
          </Button>
        </Grid>

        {user?.sessions && user.sessions.length > 0 ? (
          user.sessions.map((userSession) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={userSession._id}>
              <Card
                onClick={() => handleCardClick(userSession)}
                sx={{
                  position: 'relative',
                  width: '100%',
                  cursor: 'pointer',
                  transition: 'transform 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.02)',
                  },
                }}
              >
                <CardContent sx={{ 
                  position: 'relative',
                  minHeight: '100px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '16px',
                }}>
                  <FiberManualRecordIcon 
                    sx={{
                      position: 'absolute',
                      top: 8,
                      left: 8,
                      fontSize: 12,
                      color: userSession.isActive ? 'success.main' : 'warning.main'
                    }}
                  />

                  <Typography
                    variant="h6"
                    component="div"
                    color="text.primary"
                    align="center"
                    sx={{
                      wordBreak: 'break-word',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {userSession._id || 'Session Name'}
                  </Typography>

                  <Box sx={{ 
                    position: 'absolute', 
                    top: 8, 
                    right: 8,
                  }}>
                    {/* Solo el icono para limpiar */}
                    <IconButton
                      size="small"
                      onClick={(e) => handleCleanupSession(userSession._id, e)}
                      color="warning"
                      sx={{ 
                        backgroundColor: 'background.paper',
                        '&:hover': { backgroundColor: 'action.hover' }
                      }}
                      title="Limpiar completamente"
                    >
                      <CleaningServicesIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="body1" color="text.secondary">
              No active sessions.
            </Typography>
          </Grid>
        )}
      </Grid>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Box>
  );
};

export default SessionsComponent;