// services/campaignAnalytics.js
import axios from 'axios';
import { ENDPOINTS } from '../utils/config';

// Usamos ENDPOINTS para obtener la URL dinámica del servicio de analytics
const API_URL = ENDPOINTS.CAMPAIGN_ANALYTICS;

const CampaignAnalyticsService = {
  // Obtener o generar análisis para una campaña específica
  getCampaignAnalytics: async (jobId, userId) => {
    try {
      const response = await axios.get(`${API_URL}/${jobId}?userId=${userId}`);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  // Regenerar análisis para una campaña específica
  regenerateCampaignAnalytics: async (jobId, userId) => {
    try {
      const response = await axios.post(`${API_URL}/regenerate/${jobId}`, {
        userSessionId: userId
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  // Obtener lista de campañas analizadas del usuario
  getUserCampaigns: async (userId) => {
    try {
      const response = await axios.get(`${API_URL}/user/campaigns?userId=${userId}`);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  // Obtener resumen de todas las campañas del usuario
  getUserSummary: async (userId) => {
    try {
      const response = await axios.get(`${API_URL}/user/summary?userId=${userId}`);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  // Obtener trabajos masivos que aún no tienen análisis
  getPendingJobs: async (userId) => {
    try {
      const response = await axios.get(`${API_URL}/pending-jobs?userId=${userId}`);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  // Descargar análisis en formato Excel
  downloadCampaign: async (jobId, userId) => {
    try {
      const response = await axios.get(`${API_URL}/download/${jobId}?userId=${userId}`, {
        responseType: 'blob' // Importante para descargar archivos
      });
      return response;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  }
};

export default CampaignAnalyticsService;