// src/services/chatService.js
import axios from 'axios';
import { API_BASE_URL } from '../utils/config';

export class ChatService {
  static async checkChanges(userId, lastUpdate) {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/seguimiento/chats/${userId}/changes`, {
        params: { lastUpdate }
      });
      return response.data;
    } catch (error) {
      console.error('Error checking changes:', error);
      throw error;
    }
  }

  static async getChats(userId) {
    try {
      const response = await axios.get(`${API_BASE_URL}/seguimiento/chats/${userId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching chats:', error);
      throw error;
    }
  }

  static async updateChatStatus(userId, chatId, contactado) {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/seguimiento/chats/${userId}/${chatId}/contactado`,
        { contactado }
      );
      return response.data;
    } catch (error) {
      console.error('Error updating chat status:', error);
      throw error;
    }
  }

  // Método auxiliar para manejar la lógica de verificación y actualización
  static async getChatUpdates(userId, lastUpdate) {
    try {
      const { hasChanges, lastUpdate: newLastUpdate } = await this.checkChanges(userId, lastUpdate);
      
      if (hasChanges) {
        const chatsData = await this.getChats(userId);
        return {
          data: chatsData,
          lastUpdate: newLastUpdate
        };
      }
      
      return {
        data: null,
        lastUpdate
      };
    } catch (error) {
      console.error('Error getting chat updates:', error);
      throw error;
    }
  }
}