// messageService.js
import axios from 'axios';
import { API_ROOT } from '../utils/config';

const API_URL = API_ROOT; // Usamos API_ROOT que no incluye "/api"

// Función auxiliar para manejar errores
const handleAxiosError = (error) => {
  if (error.response) {
    // El servidor respondió con un código de estado diferente de 2xx
    console.error("Server error:", error.response.data);
    throw new Error(error.response.data.error || 'Error del servidor');
  } else if (error.request) {
    // La solicitud se realizó pero no se recibió respuesta
    console.error("Network error:", error.request);
    throw new Error('Error de red, no se pudo conectar al servidor');
  } else {
    // Error al configurar la solicitud
    console.error("Request setup error:", error.message);
    throw new Error('Error en la solicitud');
  }
};

// Clase MessageService para interactuar con los endpoints de chat
class MessageService {
  // Obtener chats recientes (contactado=false)
  // GET /api/chats/recent/:userId
  static async getRecentChats(userId, page = 1, limit = 100, skipSummary = false) {
    try {
      const response = await axios.get(`${API_URL}/api/chats/recent/${userId}`, {
        params: { page, limit, skipSummary }
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error);
    }
  }

  // Obtener todos los chats (con o sin filtro de contactado)
  // GET /api/chats/all/:userId
  static async getAllChats(userId, page = 1, limit = 100, skipSummary = false) {
    try {
      const response = await axios.get(`${API_URL}/api/chats/all/${userId}`, {
        params: { page, limit, skipSummary }
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error);
    }
  }

  // Actualizar estado de contacto de un chat
  // PATCH /api/chats/update-contactado/:id
  static async updateContactado(chatId, contactado) {
    try {
      const response = await axios.patch(`${API_URL}/api/chats/update-contactado/${chatId}`, { 
        contactado 
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error);
    }
  }

  // Actualizar nivel de interés de un chat
  // PATCH /api/chats/update-interes/:id
  static async updateInteres(chatId, interes) {
    try {
      const response = await axios.patch(`${API_URL}/api/chats/update-interes/${chatId}`, { 
        interes 
      });
      return response.data;
    } catch (error) {
      return handleAxiosError(error);
    }
  }

  static async searchChatByPhone(userId, phoneNumber, skipSummary = false) {
    try {
      const url = `${API_URL}/api/chats/search-by-phone/${userId}?phoneNumber=${encodeURIComponent(phoneNumber)}&skipSummary=${skipSummary}`;
      console.log("URL completa:", url);
      
      const response = await axios.get(url);
      console.log("Respuesta del servidor (status):", response.status);
      console.log("Respuesta del servidor (datos):", response.data);
      return response.data;
    } catch (error) {
      console.error("Error en la solicitud:", error);
      if (error.response) {
        console.error("Detalles de la respuesta de error:", {
          status: error.response.status,
          data: error.response.data
        });
      }
      return handleAxiosError(error);
    }
  }
}

export default MessageService;