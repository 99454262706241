import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Divider,
  Button,
  TextField,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BlockIcon from '@mui/icons-material/Block';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
import ImageIcon from '@mui/icons-material/Image';
import ClearIcon from '@mui/icons-material/Clear';
import { sendValidatedResponse, deleteMessage, sendManualMessage, markAllMessagesAsSent } from '../services/client';
import { useLocation, useNavigate } from 'react-router-dom';
import MessageService from '../services/messageService';

// ================== ESTILOS ================== (sin cambios) ================== //
const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  backgroundColor: '#FAFAFA',
  borderRadius: theme.shape.borderRadius,
  transition: 'box-shadow 0.3s ease',
  position: 'relative',
  '&:hover': {
    boxShadow: theme.shadows[3],
  },
  '&.exiting': {
    opacity: 0,
    transform: 'scale(0.95)',
    transition: 'opacity 0.3s ease, transform 0.3s ease',
  },
}));

const Section = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 600,
  fontSize: '0.875rem',
  marginBottom: theme.spacing(0.5),
}));

const SectionContent = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1rem',
  lineHeight: 1.6,
}));

const ActionButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

const ChatBubble = styled(Box)(({ theme, align }) => ({
  display: 'flex',
  justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
  marginBottom: theme.spacing(1),
}));

const BubbleContent = styled(Box)(({ theme, type }) => ({
  backgroundColor:
    type === 'user'
      ? '#C8E6C9' // verde claro
      : '#E3F2FD', // azul claro
  color: '#000',   // texto negro para asegurar contraste
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  maxWidth: '70%',
  wordBreak: 'break-word',
}));

const MessageDate = styled(Typography)(({ theme }) => ({
  fontSize: '0.7rem',
  color: '#9b9b9b',
  marginTop: theme.spacing(0.5),
  textAlign: 'right',
}));

const GPTSection = styled(Box)(({ theme }) => ({
  backgroundColor: '#000',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: '1200px',
  margin: '0 auto',
}));

const TopButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const PurpleButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#6a1b9a', // Color morado
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#4a148c', // Un morado más oscuro al pasar el mouse
  },
}));

const InteresSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
}));

// Función auxiliar para convertir objetos Binary a base64
const binaryToBase64 = (binary) => {
  if (!binary) return null;
  
  try {
    // Si ya es una string base64
    if (typeof binary === 'string') return binary;
    
    // Si es un objeto Binary con buffer
    if (binary.buffer) {
      return btoa(String.fromCharCode.apply(null, new Uint8Array(binary.buffer)));
    }
    
    // Si es un Buffer
    if (binary instanceof Uint8Array || (typeof Buffer !== 'undefined' && binary instanceof Buffer)) {
      return btoa(String.fromCharCode.apply(null, binary));
    }
    
    return null;
  } catch (error) {
    console.error('Error converting binary to base64:', error);
    return null;
  }
};

// Función para formatear la fecha
const formatDate = (timestamp) => {
  if (!timestamp) return '';
  
  try {
    let date;
    
    // Si es un string ISO
    if (typeof timestamp === 'string' && timestamp.includes('T')) {
      date = new Date(timestamp);
    }
    // Si es un número en segundos (WhatsApp)
    else if (typeof timestamp === 'number' && timestamp < 10000000000) {
      date = new Date(timestamp * 1000);
    }
    // Cualquier otro formato (número en milisegundos o string no ISO)
    else {
      date = new Date(timestamp);
    }
    
    // Verificar si la fecha es válida
    if (isNaN(date.getTime())) {
      console.warn('Fecha inválida:', timestamp);
      return '';
    }
    
    // Formatear la fecha
    return date.toLocaleString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  } catch (error) {
    console.error('Error formateando fecha:', error, timestamp);
    return '';
  }
};

// ================== Chat ================== //
const Chat = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  
  // Extraer datos del estado de navegación
  const { userId, conversationId, conversation: locationConversation } = location.state || {};
  console.log("conversationId:", conversationId);
  
  const [conversation, setConversation] = useState(locationConversation || null);
  const [loading, setLoading] = useState(!locationConversation);
  const [error, setError] = useState(null);
  const [editedResponse, setEditedResponse] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [exiting, setExiting] = useState(false);
  const [openImage, setOpenImage] = useState(null);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [chatHistory, setChatHistory] = useState([]);
  const [showGptResponse, setShowGptResponse] = useState(true);
  
  // Estado para el nivel de interés
  const [interes, setInteres] = useState('Nuevo');
  const [updatingInteres, setUpdatingInteres] = useState(false);
  
  // Estado para determinar qué modo de diálogo mostrar
  const [dialogMode, setDialogMode] = useState('gpt'); // 'gpt' o 'manual'
  
  // Estados para manejo de imágenes
  const [selectedImage, setSelectedImage] = useState(null);
  
  // Estado para el botón de marcar como gestionado
  const [isMarkingMessages, setIsMarkingMessages] = useState(false);

  // Función para procesar mensajes para manejar diferentes formatos
  const procesarMensaje = useCallback((msg) => {
    if (!msg) return null;
    
    // Si es un string, devolver objeto simple
    if (typeof msg === 'string') {
      return {
        role: 'user',
        text: msg,
        timestamp: new Date().toISOString() // Usar formato ISO consistente
      };
    }
    
    // Extraer y normalizar el timestamp
    let timestamp = msg.timestamp;
    
    // Normalizar el timestamp a formato ISO
    if (timestamp) {
      if (typeof timestamp === 'number') {
        // Si es un timestamp en segundos (WhatsApp suele usar segundos)
        if (timestamp < 10000000000) {
          timestamp = new Date(timestamp * 1000).toISOString();
        } else {
          // Si es un timestamp en milisegundos
          timestamp = new Date(timestamp).toISOString();
        }
      } else if (typeof timestamp === 'string') {
        // Si ya es un string ISO con 'T', lo dejamos igual
        if (!timestamp.includes('T')) {
          try {
            timestamp = new Date(timestamp).toISOString();
          } catch (e) {
            console.error('Error al convertir timestamp:', e);
            timestamp = new Date().toISOString();
          }
        }
      }
    } else {
      // Si no hay timestamp, usamos la fecha actual
      timestamp = new Date().toISOString();
    }
    
    // Si ya está procesado con mediaType
    if (msg.mediaType) {
      return {
        ...msg,
        timestamp
      };
    }
    
    // Procesar mensaje de imagen en formato WhatsApp
    if (msg.message && msg.message.imageMessage) {
      const imageMsg = msg.message.imageMessage;
      let thumbnailBase64 = binaryToBase64(imageMsg.jpegThumbnail);
      
      return {
        role: msg.key?.fromMe ? 'assistant' : 'user',
        text: imageMsg.caption || '',
        mediaType: 'image',
        mediaData: {
          preview: thumbnailBase64 ? `data:image/jpeg;base64,${thumbnailBase64}` : null,
          mimetype: imageMsg.mimetype,
          dimensions: { width: imageMsg.width, height: imageMsg.height }
        },
        timestamp
      };
    }
    
    // Procesar mensaje de audio en formato WhatsApp
    if (msg.message && msg.message.audioMessage) {
      const audioMsg = msg.message.audioMessage;
      let waveformBase64 = binaryToBase64(audioMsg.waveform);
      
      return {
        role: msg.key?.fromMe ? 'assistant' : 'user',
        text: '',
        mediaType: 'audio',
        mediaData: {
          waveform: waveformBase64,
          duration: audioMsg.seconds,
          mimetype: audioMsg.mimetype
        },
        timestamp
      };
    }
    
    // Procesar mensaje de texto en formato WhatsApp
    if (msg.message) {
      let text = '';
      
      if (msg.message.conversation) {
        text = msg.message.conversation;
      } else if (msg.message.extendedTextMessage && msg.message.extendedTextMessage.text) {
        text = msg.message.extendedTextMessage.text;
      }
      
      if (text) {
        return {
          role: msg.key?.fromMe ? 'assistant' : 'user',
          text,
          timestamp
        };
      }
    }
    
    // Si tiene un campo de texto, lo usamos directamente
    if (msg.text || msg.content) {
      return {
        role: msg.role || msg.sender || 'user',
        text: msg.text || msg.content,
        timestamp
      };
    }
    
    // Mantener el mensaje original si no requiere procesamiento especial
    return {
      ...msg,
      timestamp
    };
  }, []);

  // Función para ordenar mensajes por timestamp
  const sortMessagesByTimestamp = useCallback((messages) => {
    return [...messages].sort((a, b) => {
      // Asegurarnos de que ambos mensajes tienen timestamps válidos
      let timeA, timeB;
      
      try {
        // Para timestamp en formato ISO
        if (typeof a.timestamp === 'string' && a.timestamp.includes('T')) {
          timeA = new Date(a.timestamp).getTime();
        } 
        // Para timestamp en segundos
        else if (typeof a.timestamp === 'number' && a.timestamp < 10000000000) {
          timeA = a.timestamp * 1000;
        } 
        // Para timestamp en milisegundos o cualquier otro formato
        else {
          timeA = new Date(a.timestamp).getTime();
        }
        
        // Mismo proceso para el mensaje B
        if (typeof b.timestamp === 'string' && b.timestamp.includes('T')) {
          timeB = new Date(b.timestamp).getTime();
        } 
        else if (typeof b.timestamp === 'number' && b.timestamp < 10000000000) {
          timeB = b.timestamp * 1000;
        } 
        else {
          timeB = new Date(b.timestamp).getTime();
        }
        
        // Si alguno de los timestamps no es válido, usar 0 como fallback
        if (isNaN(timeA)) timeA = 0;
        if (isNaN(timeB)) timeB = 0;
      } catch (e) {
        console.error('Error al ordenar mensajes por timestamp:', e);
        return 0;
      }
      
      // Ordenar de más antiguo a más reciente
      return timeA - timeB;
    });
  }, []);

  // Inicializar la respuesta GPT, el historial del chat y el nivel de interés
  useEffect(() => {
    if (conversation) {
      // Si no hay respuesta GPT, activar el modo manual automáticamente
      if (!conversation.gptResponse) {
        setDialogMode('manual');
        setEditedResponse(''); // Iniciar con campo vacío para mensaje manual
      } else {
        setEditedResponse(conversation.gptResponse || '');
        setDialogMode('gpt');
      }
      
      // Inicializar el nivel de interés
      setInteres(conversation.interes || 'Nuevo');
      
      // Inicializar el historial del chat
      if (conversation.summarizedHistory && conversation.summarizedHistory.length > 0) {
        // Procesar cada mensaje para normalizar su estructura
        const processedHistory = conversation.summarizedHistory
          .map(procesarMensaje)
          .filter(Boolean);
        
        // Ordenar cronológicamente por timestamp
        const sortedHistory = sortMessagesByTimestamp(processedHistory);
        
        // Log para depuración
        console.log('Historial ordenado por timestamp:', sortedHistory);
        
        // Establecer el historial de chat ordenado
        setChatHistory(sortedHistory);
      }
      
      // Siempre mostrar la sección GPT/envío, independientemente del estado del mensaje
      setShowGptResponse(true);
    }
  }, [conversation, procesarMensaje, sortMessagesByTimestamp]);

  // Obtener la conversación cuando el componente se monta (solo si no la tenemos ya)
  useEffect(() => {
    if (locationConversation) {
      // Ya tenemos los datos de la conversación, no necesitamos cargarlos
      setLoading(false);
      return;
    }

    const fetchConversation = async () => {
      if (!userId || !conversationId) {
        setError("Información de conversación no disponible");
        setLoading(false);
        return;
      }
      
      try {
        // Aquí deberías hacer una llamada a tu API para obtener los detalles de la conversación
        // Por ejemplo: const response = await getConversationById(userId, conversationId);
        // Por ahora, mostramos un error ya que esperamos que los datos vengan en location.state
        setError("No se recibieron datos completos de la conversación");
        setLoading(false);
      } catch (err) {
        console.error("Error al cargar la conversación:", err);
        setError("No se pudo cargar la conversación");
        setLoading(false);
      }
    };
    
    fetchConversation();
  }, [userId, conversationId, locationConversation]);

  // Hacer scroll al último mensaje cuando se actualiza el historial
  useEffect(() => {
    if (chatHistory.length > 0) {
      const chatContainer = document.getElementById('chat-history');
      if (chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    }
  }, [chatHistory]);

  // Función para mostrar notificaciones
  const showNotification = (message, severity = 'success') => {
    setNotification({
      open: true,
      message,
      severity
    });
  };

  // Función para cambiar entre modos de diálogo
  const toggleDialogMode = (mode) => {
    setDialogMode(mode);
    // Limpiar campos al cambiar de modo
    if (mode === 'manual') {
      setEditedResponse('');
      setIsEditing(false);
    } else {
      setSelectedImage(null);
      setEditedResponse(conversation?.gptResponse || '');
    }
  };

  // Función para manejar la selección de imágenes
  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage({
          data: reader.result.split(',')[1], // Eliminar la parte "data:image/jpeg;base64,"
          mimetype: file.type,
          preview: reader.result
        });
      };
      reader.readAsDataURL(file);
    }
  };

  // Función para marcar todos los mensajes como gestionados
  const handleMarkAllAsRead = async () => {
    if (!conversation || !conversation.phoneNumber) return;
    
    setIsMarkingMessages(true);
    try {
      // Construir el chatId según el formato requerido
      const chatId = `${conversation.phoneNumber}@s.whatsapp.net`;
      
      // Llamar al servicio
      const result = await markAllMessagesAsSent(userId, chatId);
      
      if (result.success) {
        showNotification(`Se marcaron ${result.messagesUpdated} mensajes como gestionados`, 'success');
      } else {
        throw new Error(result.error || 'No se pudieron marcar los mensajes');
      }
    } catch (error) {
      console.error('Error al marcar mensajes como gestionados:', error);
      showNotification(`Error: ${error.message || 'Error desconocido'}`, 'error');
    } finally {
      setIsMarkingMessages(false);
    }
  };

  // Función para añadir mensajes al historial del chat
  const addMessageToHistory = (text, role = 'assistant', mediaData = null) => {
    const now = new Date().toISOString();
    
    // Crear el nuevo mensaje para añadir al historial
    const newMessage = {
      role,
      text,
      timestamp: now
    };
    
    // Añadir datos de multimedia si existen
    if (mediaData) {
      if (mediaData.type === 'image') {
        newMessage.mediaType = 'image';
        newMessage.mediaData = {
          preview: mediaData.preview,
          mimetype: mediaData.mimetype
        };
      } else if (mediaData.type === 'audio') {
        newMessage.mediaType = 'audio';
        newMessage.mediaData = {
          waveform: mediaData.waveform,
          mimetype: mediaData.mimetype
        };
      }
    }
    
    // Añadir el mensaje al historial local manteniendo el orden
    setChatHistory(prevHistory => sortMessagesByTimestamp([...prevHistory, newMessage]));
  };

  // Función para actualizar el nivel de interés
  const handleInteresChange = async (event) => {
    const newInteres = event.target.value;
    setUpdatingInteres(true);
    
    try {
      // Obtener el ID del chat desde el objeto conversation
      const chatId = `${conversation.phoneNumber}@s.whatsapp.net`;
      
      if (!chatId) {
        throw new Error("No se pudo encontrar el ID del chat");
      }
      
      // Llamar al servicio para actualizar el nivel de interés
      await MessageService.updateInteres(chatId, newInteres);
      
      // Actualizar el estado local
      setInteres(newInteres);
      
      // Actualizar el objeto de conversación
      setConversation(prev => ({
        ...prev,
        interes: newInteres
      }));
      
      showNotification(`Nivel de interés actualizado a: ${newInteres}`, 'success');
    } catch (error) {
      console.error("Error al actualizar el nivel de interés:", error);
      showNotification(`Error al actualizar el nivel de interés: ${error.message}`, 'error');
    } finally {
      setUpdatingInteres(false);
    }
  };

  // Función para manejar el envío de mensajes manuales
  const handleSendManualMessage = async () => {
    if (!editedResponse.trim() && !selectedImage) return;
    
    setIsProcessing(true);
    try {
      let messageData;
      let mediaForHistory = null;
      
      if (selectedImage) {
        // Si hay una imagen, enviamos un objeto con texto e imagen
        messageData = {
          text: editedResponse,
          mediaData: selectedImage.data,
          mediaMimetype: selectedImage.mimetype
        };
        
        mediaForHistory = {
          type: 'image',
          preview: selectedImage.preview,
          mimetype: selectedImage.mimetype
        };
      } else {
        // Si solo hay texto, enviamos el texto directamente
        messageData = editedResponse;
      }
      
      // Enviar el mensaje manual
      const result = await sendManualMessage(userId, conversation.phoneNumber, messageData);
      
      if (!result.success) {
        throw new Error(result.error || 'Error enviando mensaje');
      }
      
      // Añadir mensaje al historial local (con multimedia si existe)
      addMessageToHistory(editedResponse, 'assistant', mediaForHistory);
      
      // Limpiar los campos después de enviar
      setEditedResponse('');
      setSelectedImage(null);
      
      showNotification('Mensaje enviado con éxito', 'success');
    } catch (error) {
      console.error('Error enviando mensaje manual:', error);
      showNotification(`Error al enviar mensaje: ${error.message || 'Error desconocido'}`, 'error');
    } finally {
      setIsProcessing(false);
    }
  };

  // Función para manejar acciones de respuesta GPT
  const handleAction = async (action) => {
    setIsProcessing(true);
    try {
      switch (action) {
        case 'approve':
          // Envía la respuesta sin editar
          await sendValidatedResponse(userId, editedResponse, conversationId);
          // Añadir mensaje al historial local
          addMessageToHistory(editedResponse);
          // Limpiar el campo de respuesta después de enviar
          setEditedResponse('');
          // Cambiar a modo manual para continuar la conversación
          toggleDialogMode('manual');
          showNotification('Respuesta aprobada con éxito');
          break;
          
        case 'save':
          // Envía la respuesta editada
          await sendValidatedResponse(userId, editedResponse, conversationId);
          // Añadir mensaje al historial local
          addMessageToHistory(editedResponse);
          setIsEditing(false);
          // Limpiar el campo de respuesta después de enviar
          setEditedResponse('');
          // Cambiar a modo manual para continuar la conversación
          toggleDialogMode('manual');
          showNotification('Respuesta guardada y enviada con éxito');
          break;

        case 'delete':
          // Rechazar / Eliminar
          setExiting(true);
          setTimeout(async () => {
            await deleteMessage(conversationId);
            showNotification('Mensaje rechazado correctamente', 'info');
            // Cambiar a modo manual para continuar la conversación
            toggleDialogMode('manual');
            // Ya no navegamos de vuelta
            setExiting(false);
          }, 300);
          break;
          
        default:
          break;
      }
    } catch (error) {
      console.error(`Error processing ${action}:`, error);
      showNotification(`Error al procesar la acción: ${error.message}`, 'error');
    } finally {
      setIsProcessing(false);
    }
  };

  // Navegación y acciones UI
  const handleBack = () => {
    navigate(-1);
  };

  const handleCall = () => {
    // Obtener el número de teléfono sin código de país (asumiendo que viene con código)
    const phoneNumber = conversation.phoneNumber || '';
    
    // Quitar el código de país si existe (asumiendo que los códigos de país tienen un "+" al inicio)
    // Y también quitar el código de área (España: 34)
    let movilSinCodigoDePais = phoneNumber;
    
    if (phoneNumber.startsWith('+')) {
      // Si empieza con +, quitamos todo hasta después del código de país
      movilSinCodigoDePais = phoneNumber.replace(/^\+\d{1,3}/, '');
    } else if (phoneNumber.startsWith('34')) {
      // Si empieza con 34 (código de España), lo quitamos
      movilSinCodigoDePais = phoneNumber.substring(2);
    }
    
    // Abrir el enlace en una nueva pestaña
    window.open(`https://seguimiento.magister.com/seguimiento/?obtener=buscar&movil=${movilSinCodigoDePais}`, '_blank');
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification({...notification, open: false});
  };

  // Manejar tecla Enter para enviar mensajes en modo manual
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey && dialogMode === 'manual') {
      event.preventDefault();
      if (!isProcessing && editedResponse.trim()) {
        handleSendManualMessage();
      }
    }
  };

  if (loading) {
    return (
      <Container>
        <TopButtonsContainer>
          <PurpleButton 
            variant="contained" 
            onClick={handleBack}
            startIcon={<ArrowBackIcon />}
          >
            Volver a la lista
          </PurpleButton>
        </TopButtonsContainer>
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <TopButtonsContainer>
          <PurpleButton 
            variant="contained" 
            onClick={handleBack}
            startIcon={<ArrowBackIcon />}
          >
            Volver a la lista
          </PurpleButton>
        </TopButtonsContainer>
        <Typography color="error" align="center">
          {error}
        </Typography>
      </Container>
    );
  }

  if (!conversation) {
    return (
      <Container>
        <TopButtonsContainer>
          <PurpleButton 
            variant="contained" 
            onClick={handleBack}
            startIcon={<ArrowBackIcon />}
          >
            Volver a la lista
          </PurpleButton>
        </TopButtonsContainer>
        <Typography align="center">
          No se encontró la conversación
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <TopButtonsContainer>
        <PurpleButton 
          variant="contained" 
          onClick={handleBack}
          startIcon={<ArrowBackIcon />}
        >
          Volver a la lista
        </PurpleButton>
        
        <PurpleButton
          variant="contained"
          onClick={handleMarkAllAsRead}
          disabled={isMarkingMessages}
          startIcon={isMarkingMessages ? <CircularProgress size={20} color="inherit" /> : <CheckCircleIcon />}
        >
          Marcar como gestionado
        </PurpleButton>
      </TopButtonsContainer>
      
      {/* Sección de calificación de interés - ahora sin fondo especial */}
      <InteresSection>
        <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 1 }}>
          Calificar nivel de interés
        </Typography>
        
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel>Nivel de interés</InputLabel>
          <Select
            value={interes}
            onChange={handleInteresChange}
            label="Nivel de interés"
            disabled={updatingInteres}
          >
            <MenuItem value="Nuevo">Nuevo</MenuItem>
            <MenuItem value="Informado">Informado</MenuItem>
            <MenuItem value="Posible SI">Posible SI</MenuItem>
            <MenuItem value="Posible NO">Posible NO</MenuItem>
            <MenuItem value="Ganado">Ganado</MenuItem>
            <MenuItem value="No interesado">No interesado</MenuItem>
            <MenuItem value="Posible Futuro Interes">Posible Futuro Interés</MenuItem>
          </Select>
        </FormControl>
        
        {updatingInteres && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
            <CircularProgress size={24} />
          </Box>
        )}
      </InteresSection>
      
      <StyledCard variant="outlined" className={exiting ? 'exiting' : ''}>
        <CardContent>
          {/* Nombre / Teléfono del cliente */}
          <Section>
            <SectionTitle sx={{ 
              color: '#000000', 
              fontWeight: 600,
              textShadow: '0 0 1px rgba(255,255,255,0.5)'
            }}>
              Número de teléfono
            </SectionTitle>
            <SectionContent sx={{ fontWeight: 'bold', color: '#000000' }}>
              {conversation.phoneNumber || 'Número no disponible'}
            </SectionContent>
          </Section>
  
          <Divider sx={{ my: 2 }} />
  
          {/* Historial de Conversación */}
          <Section>
            <SectionTitle sx={{ 
              color: '#000000', 
              fontWeight: 600,
              textShadow: '0 0 1px rgba(255,255,255,0.5)'
            }}>
              Historial de Conversación
            </SectionTitle>
            {chatHistory.length > 0 ? (
              <Box 
                sx={{ 
                  pl: 1, 
                  maxHeight: 400,
                  overflowY: 'auto',
                  transition: 'max-height 0.3s ease'
                }} 
                id="chat-history"
              >
                {chatHistory.map((msg, idx) => {
                  // Procesamos el mensaje para manejar diferentes formatos
                  const processedMsg = procesarMensaje(msg);
                  
                  return (
                    <ChatBubble
                      key={idx}
                      align={processedMsg.role === 'assistant' ? 'left' : 'right'}
                    >
                      <BubbleContent type={processedMsg.role === 'assistant' ? 'assistant' : 'user'}>
                        {/* Texto del mensaje */}
                        <Typography variant="body1">
                          {typeof processedMsg === 'string' 
                            ? processedMsg 
                            : (processedMsg.text || processedMsg.content || 'No hay contenido')}
                        </Typography>
                          
                        {/* Mostrar imagen si existe en el nuevo formato mediaType */}
                        {processedMsg.mediaType === 'image' && processedMsg.mediaData?.preview && (
                          <Box
                            component="img"
                            src={processedMsg.mediaData.preview}
                            alt="Imagen adjunta"
                            loading="lazy"
                            onError={(e) => {
                              e.target.onerror = null; 
                              e.target.src = 'https://via.placeholder.com/300x200?text=Imagen+no+disponible';
                            }}
                            sx={{ 
                              mt: 1, 
                              maxWidth: '100%', 
                              maxHeight: '300px', 
                              borderRadius: 1,
                              cursor: 'pointer' 
                            }}
                            onClick={() => setOpenImage(processedMsg.mediaData.preview)}
                          />
                        )}
                        
                        {/* Compatibilidad con formato anterior */}
                        {!processedMsg.mediaType && processedMsg.image && (
                          <Box
                            component="img"
                            src={processedMsg.image}
                            alt="Adjunto"
                            loading="lazy"
                            onError={(e) => {
                              e.target.onerror = null; 
                              e.target.src = 'https://via.placeholder.com/300x200?text=Imagen+no+disponible';
                            }}
                            sx={{ 
                              mt: 1, 
                              maxWidth: '100%', 
                              maxHeight: '300px', 
                              borderRadius: 1,
                              cursor: 'pointer' 
                            }}
                            onClick={() => setOpenImage(processedMsg.image)}
                          />
                        )}
                        
                        {/* Mostrar audio si existe en el nuevo formato mediaType */}
                        {processedMsg.mediaType === 'audio' && processedMsg.mediaData && (
                          <Box sx={{ mt: 1, width: '100%' }}>
                            {/* Visualizador de forma de onda para audio */}
                            <Box 
                              sx={{ 
                                height: '40px', 
                                backgroundColor: '#f0f0f0',
                                borderRadius: '4px',
                                position: 'relative',
                                overflow: 'hidden',
                                mb: 1
                              }}
                            >
                              {processedMsg.mediaData.waveform && Array.from(
                                atob(processedMsg.mediaData.waveform)
                              ).map((char, i) => {
                                const height = (char.charCodeAt(0) / 255) * 100;
                                return (
                                  <Box
                                    key={i}
                                    sx={{
                                      position: 'absolute',
                                      left: `${(i / (processedMsg.mediaData.waveform.length * 0.75)) * 100}%`,
                                      bottom: '0',
                                      width: '2px',
                                      height: `${height}%`,
                                      backgroundColor: processedMsg.role === 'user' ? '#4caf50' : '#2196f3'
                                    }}
                                  />
                                );
                              })}
                            </Box>
                            
                            {/* Control de audio si está disponible */}
                            <Typography variant="caption" color="textSecondary">
                              {processedMsg.mediaData.duration ? `${processedMsg.mediaData.duration}s` : 'Audio'}
                            </Typography>
                          </Box>
                        )}
                        
                        {/* Compatibilidad con formato anterior */}
                        {!processedMsg.mediaType && processedMsg.audio && (
                          <Box sx={{ mt: 1, width: '100%' }}>
                            <audio controls src={processedMsg.audio} style={{ width: '100%' }}>
                              Tu navegador no soporta el elemento de audio.
                            </audio>
                          </Box>
                        )}
                        
                        {/* Mostrar fecha del mensaje */}
                        <MessageDate>
                          {formatDate(processedMsg.timestamp)}
                        </MessageDate>
                      </BubbleContent>
                    </ChatBubble>
                  );
                })}
              </Box>
            ) : (
              <SectionContent sx={{ color: '#000000' }}>No hay historial de conversación disponible</SectionContent>
            )}
          </Section>
  
          {/* Respuesta GPT / Mensaje Manual con fondo negro */}
          {showGptResponse && (
            <>
              <Divider sx={{ my: 2 }} />
              
              {/* Pestañas para cambiar entre modos */}
              <Box sx={{ mb: 2 }}>
                <Tabs 
                  value={dialogMode} 
                  onChange={(e, newValue) => toggleDialogMode(newValue)}
                  sx={{
                    backgroundColor: '#333',
                    borderRadius: '4px 4px 0 0',
                    '& .MuiTab-root': { color: '#ccc' },
                    '& .Mui-selected': { color: '#fff !important' }
                  }}
                >
                  <Tab value="gpt" label="Respuesta Sugerida" />
                  <Tab value="manual" label="Mensaje Manual" />
                </Tabs>
              </Box>
              
              <GPTSection>
                <Typography
                  variant="subtitle1"
                  sx={{ color: '#fff', fontWeight: 'bold', mb: 1 }}
                >
                  {dialogMode === 'manual' ? 'Enviar mensaje manual' : 'Enviar Respuesta Sugerida'}
                </Typography>
                
                {/* CONTENIDO SEGÚN EL MODO */}
                {dialogMode === 'manual' ? (
                  /* DIÁLOGO PARA MENSAJE MANUAL */
                  <>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      variant="outlined"
                      value={editedResponse}
                      onChange={(e) => setEditedResponse(e.target.value)}
                      onKeyPress={handleKeyPress}
                      disabled={isProcessing}
                      placeholder="Escribe tu mensaje..."
                      sx={{
                        mt: 1,
                        backgroundColor: '#000',
                        '& .MuiOutlinedInput-root': {
                          color: '#fff',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#fff',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#fff',
                        },
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#fff',
                        },
                        '& .MuiInputBase-input::placeholder': {
                          color: '#ccc',
                        },
                      }}
                    />
                    
                    {/* Selector de imágenes */}
                    {/* <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 1 }}>
                      <input
                        accept="image/*"
                        id="image-upload"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleImageSelect}
                      />
                      <label htmlFor="image-upload">
                        <Button
                          variant="outlined"
                          component="span"
                          startIcon={<ImageIcon />}
                          sx={{ color: 'white', borderColor: 'white', mr: 1 }}
                        >
                          Adjuntar imagen
                        </Button>
                      </label>
                      
                      {selectedImage && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box 
                            component="img" 
                            src={selectedImage.preview}
                            alt="Vista previa"
                            sx={{ 
                              height: 40, 
                              width: 40, 
                              borderRadius: '4px',
                              objectFit: 'cover',
                              mr: 1
                            }}
                          />
                          <Typography sx={{ color: 'white', ml: 1 }}>
                            Imagen seleccionada
                          </Typography>
                          <IconButton 
                            sx={{ color: 'white' }} 
                            onClick={() => setSelectedImage(null)}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Box>
                      )}
                    </Box> */}
                    
                    <ActionButtons>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={
                          isProcessing
                            ? <CircularProgress size={16} color="inherit" />
                            : <SendIcon />
                        }
                        onClick={handleSendManualMessage}
                        disabled={isProcessing || (!editedResponse.trim() && !selectedImage)}
                      >
                        {isProcessing ? 'Enviando...' : 'Enviar mensaje'}
                      </Button>
                    </ActionButtons>
                  </>
                ) : (
                  /* DIÁLOGO PARA RESPUESTA GPT */
                  <>
                    {isEditing ? (
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        value={editedResponse}
                        onChange={(e) => setEditedResponse(e.target.value)}
                        disabled={isProcessing}
                        sx={{
                          mt: 1,
                          backgroundColor: '#000',
                          '& .MuiOutlinedInput-root': {
                            color: '#fff',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#fff',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#fff',
                          },
                          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#fff',
                          },
                        }}
                      />
                    ) : (
                      <Typography sx={{ color: '#fff', whiteSpace: 'pre-wrap' }}>
                        {conversation.gptResponse || 'No hay respuesta disponible'}
                      </Typography>
                    )}
  
                    <ActionButtons>
                      {isEditing ? (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={
                              isProcessing
                                ? <CircularProgress size={16} color="inherit" />
                                : <CheckCircleIcon />
                            }
                            onClick={() => handleAction('save')}
                            disabled={isProcessing}
                          >
                            {isProcessing ? 'Guardando...' : 'Guardar y Enviar'}
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={() => setIsEditing(false)}
                            disabled={isProcessing}
                            sx={{ color: 'white', borderColor: 'white' }}
                          >
                            Cancelar
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={
                              isProcessing
                                ? <CircularProgress size={16} color="inherit" />
                                : <CheckCircleIcon />
                            }
                            onClick={() => handleAction('approve')}
                            disabled={isProcessing}
                          >
                            {isProcessing ? 'Enviando...' : 'Aprobar'}
                          </Button>
                          <IconButton
                            color="primary"
                            onClick={() => setIsEditing(true)}
                            disabled={isProcessing}
                            sx={{ color: 'white' }}
                          >
                            <EditIcon />
                          </IconButton>
                          <Button
                            variant="contained"
                            color="error"
                            startIcon={
                              isProcessing
                                ? <CircularProgress size={16} color="inherit" />
                                : <BlockIcon />
                            }
                            onClick={() => handleAction('delete')}
                            disabled={isProcessing}
                          >
                            {isProcessing ? 'Eliminando...' : 'Rechazar'}
                          </Button>
                        </>
                      )}
                    </ActionButtons>
                  </>
                )}
              </GPTSection>
            </>
          )}
        </CardContent>
      </StyledCard>
  
      {/* Dialog para visualizar imagen adjunta en grande */}
      <Dialog
        open={Boolean(openImage)}
        onClose={() => setOpenImage(null)}
        maxWidth="xl"
      >
        <DialogTitle>Vista previa de la imagen</DialogTitle>
        <DialogContent>
          {openImage && (
            <Box sx={{ textAlign: 'center' }}>
              <Box
                component="img"
                src={openImage}
                alt="preview-large"
                sx={{ maxWidth: '90vw', maxHeight: '90vh' }}
              />
            </Box>
          )}
        </DialogContent>
      </Dialog>
  
      {/* Notificaciones */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseNotification} 
          severity={notification.severity}
          elevation={6}
          variant="filled"
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Chat;