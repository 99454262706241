// Home.js
import React, { useState } from 'react';
import {
  Button,
  Typography,
  Box,
  Grid,
  TextField,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const handleSignUp = () => {
    if (email) {
      navigate('/register-password', { state: { email } });
    } else {
      alert('Por favor, ingresa tu dirección de correo electrónico.');
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Grid
          container
          sx={{
            width: '100%',
            maxWidth: '1200px',
            textAlign: 'left',
            padding: '0 50px',
            color: theme.palette.text.primary,
          }}
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={14}
            lg={16}
            xl={16}
            sx={{
              textAlign: 'left',
              padding: '0 20px',
              color: theme.palette.text.primary,
            }}
          >
            <Typography
              variant="h3"
              sx={{
                color: theme.palette.text.primary,
                fontSize: theme.typography.h3.fontSize,
              }}
            >
              Bienvenido a nuestra plataforma
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: theme.typography.body1.fontSize,
                color: theme.palette.text.secondary,
                display: 'block',
                marginBottom: '2rem',
              }}
            >
              Accede con tu correo electrónico para continuar.
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <TextField
                  placeholder="tucorreo@ejemplo.com"
                  variant="outlined"
                  size="large"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{
                    style: {
                      color: theme.palette.text.primary,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    height: '100%',
                  }}
                  onClick={handleSignUp}
                >
                  Registrarse
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              sx={{ marginTop: '1rem' }}
              spacing={2}
            >
              <Grid item>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  o
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  size="large"
                  variant="outlined"
                  onClick={() => navigate('/login')}
                  sx={{
                    color: theme.palette.secondary.contrastText,
                    borderColor: theme.palette.divider,
                    backgroundColor: theme.palette.secondary.main,
                  }}
                >
                  Iniciar sesión
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        component="footer"
        sx={{
          textAlign: 'center',
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.secondary,
          paddingTop: '20px',
          paddingBottom: '20px',
        }}
      >
        <Typography
          sx={{
            display: 'block',
            marginBottom: '10px',
            color: theme.palette.text.secondary,
          }}
        >
          Desarrollado por Luis Correa
        </Typography>
      </Box>
    </Box>
  );
};

export default Home;